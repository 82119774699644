import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React, {RefObject} from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { compressedImageData } from "../../../components/src/Base64Conversion"
import dayjs, { Dayjs } from "dayjs";

export const configJSON = require("./config");
let tokenData = "";

interface ListType {
  id: string | number,
  name: string
}

export interface ReceiveProject {
  id: number
  project_title: string
  project_cover_image: string
  user_name: string
  view_count: number
  like_count: number
  saved_count: number
}


export interface WorkType {
  name: string;
  value: string;
  details: string
}

export interface ProjectCountsType {
  [key: number ]: number ;
};

interface SetDataRoot {
  data: Data;
}
interface Data {
  id: string;
  type: string;
  attributes: MainAttribute;
}
interface MainAttribute {
  title: string;
  description: string;
  skills: Skill[];
  deliverables: string;
  work_type: string;
  location: string;
  experience_level: string;
  required_hours_per_week: any;
  rate_type: string;
  rate_amount: any;
  project_timeline_type: string;
  project_timeline: any;
  start_date: any;
  files_or_links: any;
  added_projects: Addedproject[];
  attached_files: Attachedfile[];
  question: string[];
  agree_to_terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  Posted_time_ago: string;
  profile_image: string;
  first_name: string;
  Post_history: Posthistory;
  is_saved_opportunity: boolean;
}
interface Posthistory {
  data: PosthistoryData[];
}
interface Addedproject {
  id: number;
  project_title: string;
  project_cover_image: string;
  user_name: string;
  view_count: number;
  like_count: number;
  saved_count: number;
}
interface PosthistoryData {
  id: string;
  type: string;
  attributes: PosthistoryAttributes;
}
interface PosthistoryAttributes {
  id: number;
  work_opportunity_id: number;
  event: string;
  event_date: string;
  referenceable_type: string;
  referenceable_id: number;
}
interface Attachedfile {
  file_name: string;
  url: string;
}
interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}

interface FileWithType {
  data: string;
  filename: string;
}

interface CreateWorkOpp {
  work_opportunity: {
    title: string;
    work_type: string;
    files: FileWithType[];
    files_or_links: string;
    deliverables: string;
    description: string;
    experience_level: string;
    required_hours_per_week: number | string;
    rate_type: string;
    project_timeline: number | string;
    project_timeline_type: string;
    rate_amount: number | string;
    agree_to_terms_and_conditions: boolean;
    location: string | string[];
    project_ids: number[];
    start_date: Dayjs;
    question: string;
    skill_ids: string | number[];
  }
}

export interface Response {
  id: string | number,
  cover_image_url: string,
  likes: string | number,
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number,
  board_id?: number,
  attributes?: {cover_image_url?:string,project_name?:string,username?:string,views?:number,likes?:number,saved_count?:number}
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  boardName: string;
  showModal: boolean;
  title: string;
  description: string;
  deliverables: string;
  descCountCharacter: number;
  selectedSkillTags: ListType[];
  skillList: ListType[]
  searchText: string;
  searchSkillList: ListType[];
  workType: string;
  deliverableCountCharacter: number;
  workExperience: string;
  files_or_links: string;
  linkList: string[];
  locationError : string,
  open:boolean;
  message: string;
  action: string;
  tempSelectedProjectList: Response[];
  selectedProjectList: Response[];
  files: File[];
  questionList: string[];
  rateData: string;
  overallRate: string;
  BudgetErrorMsg:number | string;
  projectTimeline: string;
  selectedElm: string;
  ProjectTimeError:string;
  openSingle: boolean;
  openLocation: boolean;
  workHour: string;
  warningMessage: string;
  errorMessage: string;
  openCalender: boolean;
  dateData: Dayjs;
  location: string;
  selectedLocation: string;
  multiLocationData: Array<string>;
  disableLocationText: boolean;
  conditionChecked: boolean;
  projectCounts: ProjectCountsType;
  openBoard: string;
  boardProjects : {id:string,type:string,attributes:{project:{data:object[]}}} | null;
  activeTab: string;
  boardListing : object[];
  selectLocation: ListType[];
  postOpportunityLoader: boolean;
  isModelOpen: boolean;
  uploadedFile: string[]
  edit: boolean
  receivedProjectList: ReceiveProject[]
  deleteModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DraftOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private delayTimer: ReturnType<typeof setTimeout> | null = null;
  refElm: RefObject<HTMLInputElement>;
  modalRef: RefObject<HTMLDivElement>;
  apiSearchSkillsCallId: string = "";
  apiSkillsCallId: string = "";
  apiGetBoards: string = "";
  apiGetBoardsProjects: string = "";
  apiPostOpportunityCallId: string = "";
  apiLoadDraftData:string = ''
  apiDeleteDraftData:string = ''
  cardid = this.props.cardId;
  apiUpdateStatus : string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.refElm = React.createRef<HTMLInputElement>();
    this.modalRef = React.createRef();
    this.delayTimer = null;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      boardName: '',
      showModal: false,
      title: '',
      description: '',
      deliverables: '',
      descCountCharacter: 0,
      selectedSkillTags: [],
      skillList: [],
      searchText: '',
      searchSkillList: [],
      workType: '',
      deliverableCountCharacter: 0,
      workExperience: '',
      files_or_links: '',
      linkList: [],
      open:false,
      action: '',
      message: '',
      tempSelectedProjectList: [],
      selectedProjectList: [],
      files: [],
      questionList : [],
      rateData: '',
      overallRate: '',
      BudgetErrorMsg: '',
      projectTimeline: '',
      selectedElm: "weeks",
      ProjectTimeError:'',
      openSingle: false,
      openLocation: false,
      workHour:'',
      warningMessage: '',
      errorMessage: '',
      openCalender: false,
      dateData: dayjs(),
      locationError: '',
      location: "location",
      selectedLocation: '',
      multiLocationData: [],
      disableLocationText: false,
      conditionChecked: false,
      projectCounts:{},
      openBoard: '',
      boardProjects: {id:"",type:"",attributes:{project:{data:[]}}},
      activeTab: 'myBoard',
      boardListing : [],
      selectLocation: [],
      postOpportunityLoader: false,
      isModelOpen: false,
      uploadedFile: [],
      edit: false,
      receivedProjectList: [],
      deleteModal: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiSkillsCallId) {
          this.handleSkillsListVal(message);
        } else if (apiRequestCallId === this.apiSearchSkillsCallId) {
          this.handleSearchSkillsListVal(message);
        } else if (apiRequestCallId === this.apiPostOpportunityCallId) {
          this.handlePostOpportunity(message);
        } 
      }

      switch (apiRequestCallId) {
        case this.apiLoadDraftData:
          this.setLoadData(responseJson);
          break;
        case this.apiGetBoards:
          this.handleBoardList(responseJson);
          break;
        case this.apiGetBoardsProjects:
          this.handleBoardProjects(responseJson);
          break;
        case this.apiDeleteDraftData:
          this.handleDelete(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount();
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
      this.getDataList();
    }
    this.getBoardName();
    this.loadData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.cardId !== prevProps.cardId) {
      this.cardid = this.props.cardId;
      if (this.props.cardId) {
        this.loadData();
      }
    }
  }

  async getDataList() {
    this.commonGetApiCall('skills', configJSON.getSkillEndPoint);
  }

  handleSkillsListVal = (message: Message) => {
    const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ skillList: [...dataVal.map((item: { id: string | number, name: string }) => ({ id: item.id, name: item.name }))]});
      }
    } 
    else { this.handleErrorMSg(message); }
  }

  handleSearchSkillsListVal = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage) );
    if (!responseJson.errors) { const dataVal = responseJson.data || '';
      if (dataVal) { this.setState({ searchSkillList: dataVal.map((item: { id: string | number, name: string }) => ({ id: item.id, name: item.name })) });
      }
    } else {
      this.handleErrorMSg(message);
    }
  }

  handlePostOpportunity = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      this.setState(() => ({
        ...this.state,
        open: true,
        postOpportunityLoader: false,
        action: "success",
        message: "Work opportunity posted sucessfully",
      }), () => {
        this.updateStatus();
        setTimeout(() => {
          this.redirectTo("LandingPage");
        }, 2000);
      });
    } else {
      this.handleErrorMSg(message);
    }
  }

  handleBoardList = (responseJson: any) => {
    if (!responseJson.errors){
      this.setState({boardListing: responseJson.data})
    }
  }

  handleBoardProjects = (responseJson: any) => {
    if(!responseJson.error){
      this.setState({boardProjects:responseJson.data})
    }
  }

  handleDelete = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState(() => ({
        ...this.state,
        open: true,
        postOpportunityLoader: false,
        action: "success",
        message: "Work opportunity deleted sucessfully",
      }), () => {
        setTimeout(() => {
          this.redirectTo("LandingPage");
        }, 2000);
      });
    }
  }

  setNewLoadData = (responseJson: SetDataRoot) => {
    if (responseJson.data) {

    const skillTags = responseJson.data.attributes?.skills.map((skill: { id: number, name: string }) => ({
      id: skill.id,
      name: skill.name
    }));
    const uploadedfile = responseJson.data.attributes?.attached_files.map((file: { file_name: string }) => file.file_name);
    let str = [];

    if (responseJson.data.attributes?.files_or_links != '') {
      str = responseJson.data.attributes?.files_or_links
    }
    if (str != null && str.length > 0) {
      this.setState({
        linkList: [str],
      })
    } else {
      this.setState({files: [], linkList: [], selectedProjectList: []})
    }

    this.setState({
      selectedSkillTags: skillTags,
      uploadedFile: uploadedfile,
    })
  }
  }

  setLoadData = (responseJson: SetDataRoot) => {
    this.setState({ postOpportunityLoader: false, edit : false })
    if (responseJson.data) {
      this.setNewLoadData(responseJson)
      if (responseJson.data.attributes?.rate_type == "hourly") {
        this.setState({
          rateData: responseJson.data.attributes?.rate_amount != null ? responseJson.data.attributes?.rate_amount : '',
          overallRate: '',
          BudgetErrorMsg: ''
        })
      } else {
        this.setState({
          overallRate: responseJson.data.attributes?.rate_amount != null ? responseJson.data.attributes?.rate_amount : '',
          rateData: '',
          BudgetErrorMsg: ''
        })
      }
      this.setNewData(responseJson);
      this.setState({
        title: responseJson.data.attributes?.title,
        description: responseJson.data.attributes?.description,
        deliverables: responseJson.data.attributes?.deliverables,
        workType: responseJson.data.attributes?.work_type,
        workExperience: responseJson.data.attributes?.experience_level,
        questionList: responseJson.data.attributes?.question,
        workHour: responseJson.data.attributes?.required_hours_per_week != null ? responseJson.data.attributes?.required_hours_per_week : '',
        projectTimeline: responseJson.data.attributes?.project_timeline != null ? responseJson.data.attributes?.project_timeline : '',
        selectedElm: responseJson.data.attributes?.project_timeline_type,
        receivedProjectList: responseJson.data.attributes?.added_projects,
        ProjectTimeError: '',
      })
    }
  }

  setNewData = (responseJson: SetDataRoot) => {
    if (responseJson.data.attributes.location) {
      if (responseJson.data.attributes?.location == "remote") {
        this.setState({
          location: responseJson.data.attributes?.location,
          disableLocationText: true
        })
      } else {
        this.setState({
          location: "location",
          disableLocationText: false,
          multiLocationData: responseJson.data.attributes?.location.split(',')
        })
      }
    } else {
      this.setState({
        location: "location",
        disableLocationText: false,
        multiLocationData: []
      })
    }
    let dateString = responseJson.data.attributes?.start_date;
    if (dateString == null) {
      dateString = dayjs();
    }
    const dateData = dayjs(dateString, 'YYYY-MM-DD');
    this.setState({
      dateData: dateData
    })
    const characterCount = typeof responseJson.data.attributes?.description === 'string' ? responseJson.data.attributes?.description.length : 0;
    const characterCountDeliverable = typeof responseJson.data.attributes?.deliverables === 'string' ? responseJson.data.attributes?.deliverables.length : 0;
    this.setState({
      descCountCharacter: characterCount,
      deliverableCountCharacter: characterCountDeliverable,
    })
  }

  redirectTo = (url: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), url);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleErrorMSg = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      open: true,
      postOpportunityLoader: false,
      message: responseJson.errors[0],
      action: "danger"
    });
  }

  getBoardName = async () => {
    let boardName = await getStorageData("boardName");
    if (boardName) {
      this.setState({ boardName: boardName });
    };
  }

  commonGetApiCall = (variableName: string, urlEndPoint: string) => {
    const headerObj = {
      "token": tokenData,
      "Content-Type": "application/json"
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (variableName === 'searchSkills') { this.apiSearchSkillsCallId = requestMessage.messageId;
    }
    if (variableName === "skills") {
      this.apiSkillsCallId = requestMessage.messageId;
    }
    if (variableName === 'boards'){
      this.apiGetBoards = requestMessage.messageId
    }
    if (variableName === 'boardProjects') {this.apiGetBoardsProjects = requestMessage.messageId
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTitleChange = (data: string) => {
    this.setState({ title: data })
  }

  handleDescriptionChange = (data: string) => {
    this.setState({ description: data })
  }

  handleCountCharacter = (e: string) =>{
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ descCountCharacter: characterCount });
  }

  isSkillAdded = () => {
    return this.state.selectedSkillTags.length > 0 ? true : false;
  }

  handleRemoveSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: this.state.selectedSkillTags.filter(item => item.id !== data.id), skillList: [data, ...this.state.skillList] });
  }

  handleClassess = () => {
    return this.state.selectedSkillTags.length ? "search-field main-text" : "search-field main-text h-63";
  }

  editClick = () => {
    this.setState({
      edit: !this.state.edit
    }, () => {
      this.handleEditClassess()
    })
  }

  handleEditClassess = () => {
    return !this.state.edit ? "form-container disabelDiv" : "form-container";
  }

  handleEditClass = () => {
    return !this.state.edit ? "question-container disabelDiv" : "question-container";
  }

  handleChange = (data: string) => {
    this.setState({ searchText: data });
    clearTimeout(this.delayTimer as ReturnType<typeof setTimeout>);
    this.delayTimer = setTimeout(() => {
      if (data.trim() !== "" && data.trim().length >= 2) {
        this.search(data.trim());
      } else {
        this.setState({ searchSkillList: [] })
      }
    }, 300);
  };

  search = (data: string) => {
    this.commonGetApiCall('searchSkills', `${configJSON.searchSkillEndPoint}?search_term=${data}`);
  };

  handleSkillView = () => {
    return (this.state.searchText.trim() && this.state.searchSkillList.length) || (!this.state.searchText.trim() && this.state.skillList.length);
  }

  handleSkillORSearchList = () => {
    return this.state.searchSkillList.length ? this.state.searchSkillList : this.state.skillList;
  }

  handleAddSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: [data, ...this.state.selectedSkillTags], skillList: this.state.skillList.filter(item => item.id !== data.id) });
  }

  handleWorkType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ workType: event.target.value });
  };

  handleDeliverableCountCharacter = (e:string) =>{
    const characterCount = typeof e === 'string' ? e.length : 0;
    this.setState({ deliverableCountCharacter: characterCount });
  }

  handleDeliverablesChange = (data: string) => {
    this.setState({ deliverables: data })
  }

  handleWorkExperience = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ workExperience: event.target.value });
  };

  updateLink = (data: string) => {
    this.setState({
      files_or_links: data
    });
  }

  handlelinkData = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && this.isUrlValid(this.state.files_or_links)) {
      this.setState({
        linkList: [...this.state.linkList, this.state.files_or_links],
        files_or_links: ""
      })
    }
  }

  isUrlValid = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      this.setState({
        open: true,
        message: "Please enter valid link",
        action: "danger"
      })
      return false;
    }
  }

  showModalData = () => {
    this.setState({
      showModal: true,
      tempSelectedProjectList: this.state.selectedProjectList
    }, () => {
      if (this.modalRef.current) {
        this.modalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      this.commonGetApiCall('boards', configJSON.getBoardsEndPoint)
    })
  }

  isProjectSelected = () => {
    return this.state.selectedProjectList.length > 0 ? true : false;
  }

  isProjectReceived = () => {
    return this.state.receivedProjectList.length > 0 ? true : false;
  }

  handleResourceFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxSizes = {
      image: 2 * 1024 * 1024,
      video: 15 * 1024 * 1024,
    };
    const fileTypes = {
      image: ["image/jpeg", "image/png", "image/jpg"],
      video: ["video/mp4", "video/mov", "video/mkv"],
    };
    if (!event.target.files) return;
    const tempFile = event.target.files[0];
    const isVideo = fileTypes.video.includes(tempFile.type);
    const isImage = fileTypes.image.includes(tempFile.type);
    if (!this.validFileFormat(tempFile)) {
      this.refElm.current && (this.refElm.current.value = "");
      return;
    }
    const maxFileSize = isVideo ? maxSizes.video : maxSizes.image;
    if (tempFile.size > maxFileSize) {
      this.setState({
        action: "danger",
        open: true,
        message: `File size exceeds ${isVideo ? "15MB" : "2MB"}. Please upload a smaller file.`
      });
      this.refElm.current && (this.refElm.current.value = "");
      return;
    }
    if (isImage) {
      const compressedFile = await compressedImageData(tempFile, 0, 0, 0.7);
      this.setState(prevState => ({
        files: [...prevState.files, compressedFile]
      }));
    } else {
      this.setState(prevState => ({ files: [...prevState.files, tempFile]
      }));
    }
    this.refElm.current && (this.refElm.current.value = "");
  }

  validFileFormat = (fileData: File) => {
    const validFileTypes = [
      "video/mp4",
      "image/jpeg",
      "image/png",
      "video/mov",
      "video/mkv",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ];
    if (!validFileTypes.includes(fileData.type)) {
      this.setState({
        open: true,
        message: "Please select a valid file (jpg, png, mp4, mov, mkv, pdf, doc, docx).",
        action: "danger"
      });
      return false;
    }
    return true
  }

  isFileAdded = () => {
    return this.state.files.length > 0 ? true : false;
  }

  isFileReceived = () => {
    return this.state.uploadedFile.length > 0 ? true : false;
  }

  handleAddClass = (index: number) => {
    return `question-data ${index === 0 ? "mt-50" : "mt-16"}`;
  }

  handleQuestionData = (data: string, index: number) => {
    const indexData = [...this.state.questionList];
    indexData[index] = data;
    this.setState({
      questionList: [...indexData]
    })
  }

  removeQuestion = (data: string, index: number) => {
    this.setState({
      questionList: this.state.questionList.filter((item, index1) => index1 !== index)
    })
  }

  addQuestion = () => {
    this.setState({
      questionList: [...this.state.questionList, ""]
    })
  }

  updateRate = (data: string) => {
    let budgetdata = Number(data)
    if (this.isnumericVal(data)) {
      if (budgetdata > 5000) {
        this.setState({
          overallRate: "",
          BudgetErrorMsg: 'Hourly rate must be up to 5000'
        })
      } else {
        this.setState({
          rateData: data,
          overallRate: "",
          BudgetErrorMsg: ''
        })
      }
    }
  }

  isnumericVal = (data: string) => data === "" || /^[0-9\b]+$/.test(data);

  updateOverallRate = (data: string) => {
    if (this.isnumericVal(data)) {
      if (Number(data) > 9999999){
        this.setState({
          rateData: "",
          BudgetErrorMsg : 'Overall budget must be less than 99,999,99'
        });
      }else{
        this.setState({
          overallRate: data,
          rateData: "",
          BudgetErrorMsg : '',
        });
      }
      
    }
  }

  handleProjectTimelineValidation = () => {
    const timeLineData = this.state.projectTimeline ? Number(this.state.projectTimeline) : 0;
    if (timeLineData && (this.state.selectedElm == "weeks") && (timeLineData > 52)) {
      return false
    }
    if (timeLineData && (this.state.selectedElm == "months") && (timeLineData > 12)) {
      return false
    }
    return true
  }

  updateProjectTimeline = (data: string) => {
    let Timeline = Number(data)
    if (Timeline >=0 ) {
      if (this.state.selectedElm == "months") {
        this.setState({
          projectTimeline: data,
          ProjectTimeError : Timeline > 12? 'You cannot enter more than 12 months' : ""
        })
      } else {
        this.setState({
          projectTimeline: data,
          ProjectTimeError : Timeline > 52? 'You cannot enter more than 52 weeks' : ""
        })
      }
    }
  }

  toggleSingle = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ openSingle: !this.state.openSingle, openLocation: false });
  }

  handleSelectChange = (data: string) => {
    this.setState({ selectedElm: data, projectTimeline: '' });
  }

  handleWorkHoursValidation = () => {
    if (this.state.workHour && (Number(this.state.workHour) > 168)) {
      return false
    }
    return true
  }

  updateWorkHours = (data: string) => {
    if (this.isnumericVal(data)) {
      if (data.length <= 2) {
        const hours = parseInt(data, 10);
        if (data === '' || (hours >= 0 && hours <= 99)) {
          this.setState({
            warningMessage: hours > 56 ? 'You cannot enter more than 56 hours.' : '',
            workHour: data
          });
        }
      }
    }
  };



  handleRemoveLocation = (data: string) => {
    this.setState({ multiLocationData: this.state.multiLocationData.filter(item => item !== data)});
  };

  toggleCondition = () => {
    this.setState({ conditionChecked: !this.state.conditionChecked })
  }

  handleCheckBg = () => {
    return this.state.conditionChecked ? "#d9d9d9" : "#111";
  }

  handleShowDiscard = () => {
    const headerObj = {
      "token": tokenData
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteDraftData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificData + this.props.cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ postOpportunityLoader: true });
  }

  checkForDate = () => {
    const currentData = dayjs().format("L");
    const existData = this.state.dateData.format("L");
    if (existData !== currentData) {
      return true
    } else {
      return false
    }
  }

  handleCurrentDate = (): Dayjs => {
    return dayjs();
  }

  handleDate = (data: Dayjs | null) => {
    if(data){
      this.setState({ dateData: data })
    }
  }

  closeCalender = () => {
    this.setState({
      openCalender: false
    });
  }

  openCalender = () => {
    this.setState({
      openCalender: true
    });
  }

  handleWorkLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      location: event.target.value,
      multiLocationData : [],
      locationError:""
    }, this.handleDisableButton)
  }

  handleChangeLocationText = (data: string) => {
    this.setState({ selectedLocation: data})
  };

  handleChangeLocation = (data: string) => {
    this.setState((prevState):any => { const isAlreadyAdded = prevState.multiLocationData.some(
        (location: string) => location === data
      );
      if (isAlreadyAdded) {
        this.setState({
          locationError:"Location is already selected"
        })
        return { 
          selectedLocation: "" 
        };
      } else {
        this.setState({locationError:""})
        return {
          multiLocationData: [data, ...prevState.multiLocationData],
          selectedLocation: data,
        };
      }
    }, () => {
      this.setState({ 
        selectedLocation: "" 
      });
    });
  };

  handleDisableButton =() => {
    if(this.state.location == "remote"){
      this.setState({ disableLocationText: true, selectedLocation: '' });
    }
    else{
      this.setState({ disableLocationText: false });
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
      tempSelectedProjectList: [],
      projectCounts:{}
    });
    this.handleAccordian("");
  }

  handleAccordian = (data: string) => {
    this.setState({
      openBoard: this.state.openBoard === data ? "" : data,
      boardProjects : null
    });

    this.getBoardsProjects(data)
  }



  enablePostWorkOpportunity = () => {
    if (
      this.state.title &&
      this.state.description &&
      this.state.selectedSkillTags.length &&
      this.state.projectTimeline &&
      (this.state.rateData || this.state.overallRate) &&
      this.state.location &&
      this.state.workHour &&
      this.state.selectLocation &&
      this.state.deliverables &&
      this.state.workExperience &&
      this.state.conditionChecked
      && !this.state.warningMessage
      && !(this.state.multiLocationData.length === 0 && this.state.location === "location")
    ) {
      return true
    } else {
      return false
    }
  }

  getBoardsProjects = (boardId:string) =>{
    this.commonGetApiCall("boardProjects",`${configJSON.getBoardsEndPoint}/${boardId}`)
  }

  addProject = () => {
    this.setState({
      selectedProjectList: [...this.state.tempSelectedProjectList],
      tempSelectedProjectList: [],
      projectCounts:{},
      showModal: false
    });
    this.handleAccordian("");
  }

  enableProjectAddBtn = () => {
    return this.state.tempSelectedProjectList.length ? false : true
  }

  enableProjectAddBG = () => {
    return this.enableProjectAddBtn() ? "disabled-btn" : "secondary-btn";
  }

  handleTab = (data: string) => {
    this.setState({
      activeTab: data
    })
  }

  enableTabBG = (data: string) => {
    return (this.state.activeTab === data) ? "secondary-text" : "disabled-text";
  }

  handleViewCheck = (item: Response,data:number) => {
    const matchedData = this.state.tempSelectedProjectList.find(project => project.id === item.id && project.board_id === data);
    return matchedData ? true : false
  }

  toggleSelectProject = (item: Response, data:number) => {
    const removedData = this.state.tempSelectedProjectList.filter(project => project.id !== item.id);
    const matchedData = this.state.tempSelectedProjectList.find(project => project.id === item.id );
    if (matchedData) {
      this.setState(prevState => (
        {
        tempSelectedProjectList: [...removedData],
        projectCounts: {
          ...prevState.projectCounts,
          [data]: prevState.projectCounts[data] > 0 ? prevState.projectCounts[data] - 1 : 0
        }
      }));
    } else { item.board_id = data;
      this.setState(prevState => ({
        tempSelectedProjectList: [...prevState.tempSelectedProjectList, item],
        projectCounts: {
          ...prevState.projectCounts,
          [data]: (prevState.projectCounts[data] || 0) + 1
        }
      }));
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  }

  handleModel = () => {
    this.setState({isModelOpen:!this.state.isModelOpen})
  }

  enablebuttonText = () => {
    return this.enablePostWorkOpportunity() ? "secondary-btn" : "disabled-btn";
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1]; 
        resolve(base64String || '');
      };
      reader.onerror = error => reject(error);
    });
  }

  createWorkOpportunity = async () => {
    this.setState({isModelOpen:false})
    if (!this.handleWorkHoursValidation()) {
      return
    }
    if (!this.handleProjectTimelineValidation()) {
      return
    }
    const skillListData = this.state.selectedSkillTags.map(item => item.id)  as number[];
    
    const linkListData = this.state.linkList.join();
    const questionListData = this.state.questionList.join();   
    const projectData = this.state.selectedProjectList.map(item => item.id)  as number[]
    const filesDataPromises =  this.state.files.map(async (file: File) => ({
      filename: file.name,
      data: await this.fileToBase64(file)
    })) ;

    let newLocation = this.state.multiLocationData.map((value)=>{
      let arr = value.split(',')
      return arr[0].toLowerCase()
    })
    const filesData: FileWithType[] = await Promise.all(filesDataPromises);
    const formData:CreateWorkOpp = {
      work_opportunity:{
      title: this.state.title,
      files:  filesData,
      files_or_links: linkListData,
      work_type: this.state.workType,
      deliverables: this.state.deliverables,
      description: this.state.description,
      experience_level: this.state.workExperience,
      required_hours_per_week: this.state.workHour,
      project_timeline_type: this.state.selectedElm,
      rate_type: this.state.rateData ? "hourly" : "overall",
      project_timeline: this.state.projectTimeline,
      rate_amount: this.state.rateData || this.state.overallRate,
      location: this.state.location == "remote" ? "remote" : (newLocation).toString() ,
      question: questionListData,
      start_date:this.state.dateData,
      agree_to_terms_and_conditions: this.state.conditionChecked,
      project_ids: projectData,
      skill_ids: skillListData}
  };
  this.commonPostApiCall(configJSON.createWorkOpportunity+this.props.cardId, formData);
}

updateStatus = () => {
  const headerObj = {
    "token": tokenData,
    'Content-Type': 'application/json'
  };
  const body = {
    "data": {
      "attributes": {
        "work_opportunity_status": "open_opportunity"
      }
    }
  }
  const headerData = JSON.stringify(headerObj);
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiUpdateStatus = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updateStatus + this.props.cardId
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    headerData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({ postOpportunityLoader: true });
}

commonPostApiCall = (urlEndPoint: string, bodyData: CreateWorkOpp) => {
  const headerObj = {
    'Content-Type':'application/json',
    "token": tokenData,
  };
  const headerData = JSON.stringify(headerObj);
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiPostOpportunityCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),urlEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    headerData);
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(bodyData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.putApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({ postOpportunityLoader: true });
}

  loadData = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': 'application/json'
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiLoadDraftData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificData + this.props.cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ postOpportunityLoader: true });
  }

  async componentWillUnmount() {
    removeStorageData("saveDraft")
    removeStorageData("DeleteDraft")
    removeStorageData("CardId")
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value) || value === "") {
      event.target.value = value;
    } else {
      event.preventDefault();
      event.target.value = value.replace(/[^a-zA-Z\s]/g, '');
    }
  };

  handleCancel = () =>{
    this.setState({ searchText: "",searchSkillList:[] })
  }

  handleDeleteModel = () => {
    this.setState({deleteModal: !this.state.deleteModal})
  }
  // Customizable Area End
}