import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { getStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");
let tokenData = "";

type DateInput = string | number | Date | Dayjs | null;

export interface ContractDetail {
  status?: string
  deadline?: string
  proposal?: ContractDetailProposal
  contract?: ContractDetailContract
  time_limit_exceed?: boolean
  action_not_taken_by?: string | null
}

export interface ContractDetailProposal {
  data?: ContractDetailProposalData
}

export interface ContractDetailProposalData {
  id: string
  type: string
  attributes: ContractDetailProposalDataAttributes
}

export interface ContractDetailProposalDataAttributes {
  id: number
  created_at: string
  updated_at: string
  service_rate: number
  give_your_pitch: string
  profile_photo: string
  background_color: string
  location: string
  user_name: string
  likes_count: number
  view_count: number
  rating: number
  received_time_ago: string
}

export interface ContractDetailContract {
  data?: ContractDetailContractData
}

export interface ContractDetailContractData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributes
}

export interface ContractDetailContractDataAttributes {
  id: number
  proposal_id: number
  account_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  project_timeline: number
  created_at: string
  updated_at: string
  contract_status?: ContractDetailContractDataAttributesContractStatus
  milestones: ContractDetailContractDataAttributesMilestones
  contract_sent_on_date: string
  take_action_until: string
  edit_request_n_date: string
  awaing_action_till: string
  start_date: string
  edited_start_date: string
  work_opportunity_title: string
  after_edit_project_timeline: number
  after_edits_overall_budget: number
  contract_overall_budget: number
  project_timeline_type: string
  project_rate_type: string
}

export interface ContractDetailContractDataAttributesContractStatus {
  id: number
  receiver_id: number
  status: string
  contract_id: number
  created_at: string
  updated_at: string
}

export interface ContractDetailContractDataAttributesMilestones {
  data: ContractDetailContractDataAttributesMilestonesData[]
}

export interface ContractDetailContractDataAttributesMilestonesData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributes {
  id: number
  contract_id: number
  name: string
  description: string
  amount: number
  due_date: string
  client_approval: boolean
  deliverables: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverables
  edits?: ContractDetailContractDataAttributesMilestonesDataAttributesEdits | null
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverables {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesData[]
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesDeliverablesDataAttributes {
  deliverable: string
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEdits {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesEditsData
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributes {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  contract_milestone_id: number
  edit_deliverables: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverables
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverables {
  data: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesData[]
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesData {
  id: string
  type: string
  attributes: ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesDataAttributes
}

export interface ContractDetailContractDataAttributesMilestonesDataAttributesEditsDataAttributesEditDeliverablesDataAttributes {
  deliverable_id: number
  deliverable: string
}

export interface DeclinedResponse {
  message: string
  contract: DeclinedContract
}

export interface DeclinedContract {
  id: number
  proposal_id: number
  account_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  created_at: string
  updated_at: string
  work_opportunity_id: number
  activate: boolean
  start_date: string
  end_date: Date | string
  edited_start_date: string
  contract_overall_budget: number
}

export interface AcceptContractResponse {
  title: string
  start_date: string
  project_timeline: string
  overall_budget: string
  contract: AcceptContract
  milestones: AcceptMilestone[]
}

export interface AcceptContract {
  id: number
  proposal_id: number
  account_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  created_at: string
  updated_at: string
  work_opportunity_id: number
  activate: boolean
  start_date: string
  end_date: Date | string
  edited_start_date: string
  contract_overall_budget: number
}

export interface AcceptMilestone {
  id: number
  name: string
  description: string
  amount: number
  due_date: string
  deliverables: string[]
}

export interface DeletePost {
  message: string
  error: string
}

export interface ActiveContractMain {
  contract: ActiveContract
  message: string
  errors: [error: string]
}

export interface ActiveContract {
  account_id: number
  id: number
  activate: boolean
  proposal_id: number
  work_opportunity_id: number
  terms_and_condition_accept: boolean
  decline_contract: boolean
  created_at: string
  updated_at: string
  start_date: string
  end_date: any
  edited_start_date: any
  contract_overall_budget: number
}

export interface DropDownElement {
  id: number
  name: string
  color: string
  value: string
}

interface IdMap {
  [key: string]: number;
};
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cardId: string;
  dropDownId: number;
  deletePost?: () => void;
  activeOpportunity?: (element: DropDownElement) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  contentType: string;
  activeId: string;
  SelectedDropDownID:number;
  contractAllDetails: ContractDetail
  contractDtailsProposal: ContractDetailProposal
  contractDtailsContract: ContractDetailContract
  mileStoneExpandData: { 
    expandedTerms: IdMap 
  },
  moreMilestone: boolean
  milestoneStatusCheck: boolean
  deleteModal: boolean
  deleteProjectModal: boolean
  acceptModel: boolean
  conditionChecked: boolean;
  exceedTime: boolean;
  takenAction: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DraftOpportunityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cardid = this.props.cardId;
  apiWorkOpportunityContract : string = ''
  apiDeclineContract: string = ''
  apiAcceptContract: string = ''
  apiDeletePostData: string = ''
  apiActiveContract: string = ''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      contentType: "awaiting",
      activeId: "",
      SelectedDropDownID:2,
      contractAllDetails: {},
      contractDtailsProposal: {},
      contractDtailsContract: {},
      mileStoneExpandData: {
        expandedTerms: {}
      },
      moreMilestone: false,
      milestoneStatusCheck: false,
      deleteModal: false,
      deleteProjectModal: false,
      acceptModel: false,
      conditionChecked: false,
      exceedTime: false,
      takenAction: "client"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.apiWorkOpportunityContract:
          this.handleWorkOpportunity(responseJson);
          break;
        case this.apiDeclineContract:
          this.handleDeclinedContract(responseJson)
          break;
        case this.apiAcceptContract:
          this.handleAcceptContract(responseJson)
          break;
        case this.apiDeletePostData:
          this.handleDelete(responseJson);
          break;
        case this.apiActiveContract:
          this.handleActiveContract(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let userInfo = await getStorageData("userInfo");
    let storeData = JSON.parse(userInfo);
    if (userInfo) {
      tokenData = storeData.meta.token;
    }
    this.getContarctData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.props.cardId !== prevProps.cardId) {
      this.cardid = this.props.cardId;
      if (this.props.cardId) {
        this.getContarctData();
      }
    }
  }

  setMilestoneStatus = () => {
    if (this.state.contractAllDetails.status) {
      const checkStatus = this.checkMilestoneString(this.state.contractAllDetails.status) ||  this.state.contractAllDetails.status.toLowerCase().includes("declined edits")
      if (checkStatus) {
        this.setState({ milestoneStatusCheck: true })
      } else {
        this.setState({ milestoneStatusCheck: false })
      }
    }
  }

  setExceedTime = () => {
    if (this.state.contractAllDetails.time_limit_exceed) {
      this.setState({ exceedTime: true })
    } else {
      this.setState({ exceedTime: false })
    }
  }

  setTakenAction = () => {
    if (this.state.contractAllDetails.action_not_taken_by) {
      if (this.state.contractAllDetails.action_not_taken_by == 'designer') {
        this.setState({ takenAction: "designer" })
      } else {
        this.setState({ takenAction: "client" })
      }
    }
  }

  handleWorkOpportunity = (response: ContractDetail) => {
    if (response.proposal && response.contract) {
      this.setState({
        contractAllDetails: response,
        contractDtailsProposal: response.proposal,
        contractDtailsContract: response.contract,
      }, () => {
        this.setMilestoneStatus()
        this.setExceedTime()
        this.setTakenAction()
      })
    }
  }

  handleDeclinedContract = (responseJson: DeclinedResponse) => {
    if (responseJson && responseJson.contract) {
      if (responseJson.message == "Contract declined successfully") {
        this.getContarctData()
      }
    }
  }

  handleAcceptContract = (response: AcceptContractResponse) => {
    if (response && response.contract) {
      this.setState({ acceptModel: false })
      this.getContarctData()
    }
  }

  handleDelete = (responseJson: DeletePost) => {
    if (responseJson.message == "Work opportunity successfully deleted") {
      this.props.deletePost?.()
    }
  }

  handleActiveContract = (responseJson: ActiveContractMain) => {
    if (responseJson.message == "contract activated successfully") {
      this.props.activeOpportunity?.({
        "id": 3,
        "name": "Activated",
        "color": "green",
        "value": "activated"
      })
      }
  }

  navigateViewWork = () => {
    this.props.navigation.navigate("ViewWorkDetail",{id: this.props.cardId, dropdownId: this.props.dropDownId})
  }

  formatDateShort = (dateString: DateInput | undefined) => {
    if (dateString) {
      const date = dayjs.isDayjs(dateString) ? dateString.toDate() : new Date(dateString);
      const options: Intl.DateTimeFormatOptions = { year: '2-digit', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }
  }

  displayAllProposal = () => {
    this.setState({
      contentType: 'proposalList'
    })
  }

  displayAwaiting = () => {
    this.setState({
      contentType: 'awaiting'
    })
  }

  toggleTerm = (index: number) => {
    this.setState((prevState) => ({
      mileStoneExpandData: {
        ...prevState.mileStoneExpandData,
        expandedTerms: {
          ...prevState.mileStoneExpandData.expandedTerms,
          [index]: !prevState.mileStoneExpandData.expandedTerms[index],
        }
      }
    }));
  };

  checkMoreMilestone = () => {
    this.setState({ moreMilestone: true })
  }

  getContarctData = () => {
    const headerObj = {
      "token": tokenData,
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiWorkOpportunityContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.workOpportunityContactApiEndPoint + this.props.cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  checkMilestoneString = (inputString: string | undefined) => {
    if (inputString) {
    const regex = /\b\d+\s+milestones\b/i;
    return regex.test(inputString);
    }
  };

  checkAwaitRequestStatus = (): boolean => {
    const inputString = this.state.contractAllDetails.status;
    if (inputString) {
      const milestoneRegex = /\b\d+\s+milestones\b/i;
      return milestoneRegex.test(inputString) || inputString.toLowerCase().includes("awaiting");
    } else {
      return false;
    }
  };

  checkMilestoneTimeline = (): boolean => {
    const inputString = this.state.contractAllDetails.status;
    if (inputString) {
      return inputString.toLowerCase().includes("awaiting") || inputString.toLowerCase().includes("declined offer") || inputString.toLowerCase().includes("accepted");
    } else {
      return false;
    }
  }

  checkDeclinedStatus = (): boolean => {
    const inputString = this.state.contractAllDetails.status;
    if (inputString) {
      return inputString.toLowerCase().includes("declined");
    } else {
      return false;
    }
  };

  checkDeclinedEditStatus = (): boolean => {
    const inputString = this.state.contractAllDetails.status;
    if (inputString) {
      return inputString.toLowerCase().includes("declined edit");
    } else {
      return false;
    }
  };

  checkAcceptedStatus  = (): boolean => {
    const inputString = this.state.contractAllDetails.status;
    if (inputString) {
      return inputString.toLowerCase().includes("accepted");
    } else {
      return false;
    }
  };

  getFormattedText = () => {
    let text = this.state.contractAllDetails.status;
    if (text) {
      const words = text.split(' ');
      if (words.length < 2) return text;

      const remaining = words.slice(0, -2).join(' ');
      const lastTwo = words.slice(-2).join(' ');

      return (
        <>
          {remaining} <u>{lastTwo}</u>
        </>
      );
    }
  };

  handleDeleteModel = () => {
    this.setState({ deleteModal: !this.state.deleteModal })
  }

  handleDeleteProjectModel = () => {
    this.setState({ deleteProjectModal: !this.state.deleteProjectModal })
  }

  handleAcceptModel = () => {
    this.setState({ acceptModel: !this.state.acceptModel })
  }

  handleShowDiscard = () => {
    this.declineContarct()
  }

  handleCheckBg = () => {
    return this.state.conditionChecked ? "#d9d9d9" : "#111";
  }

  toggleCondition = () => {
    this.setState({ conditionChecked: !this.state.conditionChecked })
  }

  enablePostWorkOpportunity = () => {
    if (
      this.state.conditionChecked
    ) {
      return true
    } else {
      return false
    }
  }

  handleAcceptContractClick = () => {
    this.acceptContarct();
  }

  declineContarct = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': configJSON.ApiContentType,
    };
    const body = {
      "data": {
        "attributes": {
          "work_opportunity_id": this.props.cardId,
          "contract_id": this.state.contractAllDetails.contract?.data?.id
        }
      }
    }
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeclineContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.declinedApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  acceptContarct = () => {
    const headerObj = {
      "token": tokenData
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAcceptContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptEditApiEndPoint + this.state.contractAllDetails.contract?.data?.id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeletePost = () => {
    const headerObj = {
      "token": tokenData
    };
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeletePostData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpecificData + this.props.cardId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  activeContract = () => {
    const headerObj = {
      "token": tokenData,
      'Content-Type': configJSON.ApiContentType,
    };
    const body = {
      "data": {
        "attributes": {
          "contract_id": this.state.contractAllDetails.contract?.data?.id
        }
      }
    }
    const headerData = JSON.stringify(headerObj);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiActiveContract = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headerData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}