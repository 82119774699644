import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getToken, getUserProfile } from "../../../components/src/Base64Conversion";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  tabValues:number;
  findWorkSearch:string;
  selectedSort:{
    name:string;
    value:string;
  };
  isSearched:boolean;
  filterData:{key:number;name:string;select:boolean}[];
  loading:boolean;
  token:string;
  userType:string;
  activeData:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContractController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  offereContractApiId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      tabValues:0,
      isSearched:false,
      loading:false,
      activeData:[],
      findWorkSearch:"",
      token:"",
      userType:"",
      filterData:[
        {key:0,name:"New Notification",select:false},
        {key:1,name:"New message",select:false},
        {key:2,name:"Submission approval pending",select:false},
        {key:3,name:"Milestone activation pending",select:false},
        {key:4,name:"Submission pending",select:false},
      ],
      selectedSort:{
        name: "Recently Listed",
        value:'recently_listed'
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // code here
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId==this.offereContractApiId){
        this.setState({loading:false})
        if(!responseJson.error){
          this.setState({activeData:responseJson})
        }
       console.log(responseJson,"heysssduwdsudye")
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    await this.getTokenData()
      if(this.state.tabValues==0){
        this.getDataList()
      }
  }
 
  handleSortSelect = (name:string,value:string) =>{
    this.setState({selectedSort:{name,value}})
  }

  handleFindWorkSearch=(event:any)=>{
    this.setState({findWorkSearch:event.target.value})
  }
 
  handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValues: newValue  })
  };
  a11yProps23(index:number, label:string) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: label,
    };
  }
  toggleSelect = (key:number) => {
    const updatedFilterData = this.state.filterData.map((item) =>
      item.key === key ? { ...item, select: !item.select } : item
    );
  
    this.setState({ filterData: updatedFilterData });
  };
  handleEndPoint=()=>{
if(this.state.tabValues==0){
  if(this.state.userType=="designer"){

    return 'bx_block_cfdesignersidecontractmanagement/designers_contracts/designer_activate_contracts_list??page=1&per_page=10'
  }
  else{
    return 'bx_block_dashboard/contracts/client_view_contracts?page=1&per_page=10'
  }
}
  }

  getTokenData = async () => {
    const token = await getToken();
     let userInfo= await getStorageData("userInfo")
     let UserType=JSON.parse(userInfo)
    this.setState({ token: token ,userType:UserType?.data?.attributes?.role_name})
};

  getDataList = async () => {
    this.setState({ loading: true })
    const headers = {
        "Content-Type": "application/json",
        token: this.state.token,
    };
    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.offereContractApiId = getValidationsMsg.messageId
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.handleEndPoint())
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  // Customizable Area End
}