import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Box,
  styled
} from '@material-ui/core';
import { closeIcon } from "../../blocks/landingpage/src/assets";
import '../../blocks/cfproposalmanagement/src/style.css'

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      backgroundColor: '#222222',
      padding: '50px',
      width: '572px',
      borderRadius: '12px',
      margin: '0'
    },
    title: {
      color: '#F5E8D3',
      fontWeight: 600,
      textAlign: 'center' as const,
      marginTop: '6px',
      fontFamily: 'Inter-Medium, sans-serif',
      fontSize: '20px',
      lineHeight: '24.2px',
      padding: "30px 0 20px",
      borderBottom: "1px solid rgba(84, 84, 84, 1)",
      width: '100%'

    },
    content: {
      backgroundColor: '#171717',
      borderRadius: '8px',
      padding: '20px',
      textAlign: 'center' as const,
      margin: '0 auto'
    },
    contentBox: {
      padding: '50px'
    },
    typography: {
      color: '#F5E8D3',
      fontSize: '16px',
      fontFamily: 'Inter-Medium, sans-serif',
      fontWeight: 400,
      lineHeight: '24.88px',
      textAlign: 'center'
    },
    cancelButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#fff2e3",
      backgroundColor: "#0d0d0d",
      width: '142px',
      marginRight: '20px',
      "&:hover": {
        backgroundColor: "#0d0d0d",
      }
    },
    deleteButton: {
      textTransform: "none",
      height: "44px",
      borderRadius: "7px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontFamily: "Inter-Medium,sans-serif",
      lineHeight: "24px",
      letterSpacing: "0.38px",
      fontSize: "16px",
      display: "flex",
      border: "none",
      gap: "10px",
      color: "#121212",
      backgroundColor: "#fff2e3",
      width: '142px',
      "&:hover": {
        backgroundColor: "#FFF2E3",
      }
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 0 40px',
    },
  });

const DioalogComponent = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: 'unset',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '478px',
  },
  '& .MuiPickersLayout-contentWrapper': {
    background: 'rgba(34, 34, 34, 1) '

  },
  '& .MuiDialogContent-root': {
    paddingInline: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(34, 34, 34, 1)'
  }
})

interface DeleteProjectModalProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string
  content: string
  button1Text: string
  button2Text: string
}

const DeclinedModal: React.FC<DeleteProjectModalProps> = ({ classes, open, onClose, onDelete, title, content, button1Text, button2Text }) => {
  return (
    <DioalogComponent
      open={open}
      onClose={onClose}
      fullWidth={true}
      data-test-id="close-onclick"
      style={{
        scrollbarWidth: 'none'
      }}
      maxWidth="sm"
    >
      <Box style={{ height: 40, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          style={{ cursor: 'pointer', width: '14px', height: '14px' }}
          src={closeIcon}
          data-test-id="closeButton"
          alt="Close"
          onClick={() => onClose()}
        />
      </Box>
      <DialogContent
        style={{
          borderRadius: '10px',
          border: '1px solid rgba(95, 95, 95, 1)',
        }}
      >
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.contentBox}>
          <Typography className={classes.typography}>
            {content}
          </Typography>
        </Box>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose} className={classes.cancelButton}>
            {button1Text}
          </Button>
          <Button
            onClick={() => {
              onDelete();
              onClose();
            }}
            className={classes.deleteButton}
          >
            {button2Text}
          </Button>
        </DialogActions>
      </DialogContent>
    </DioalogComponent>
  );
};

export default withStyles(styles)(DeclinedModal);
