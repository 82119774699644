// Customizable Area Start
import React from "react";

import UnderReviewController, {

} from "./UnderReviewProposalController";
import { AccordionSummary, Box, Button, CircularProgress, Divider, Grid, Step, StepLabel, Stepper, styled, Typography } from "@material-ui/core";
import { FlatList, View, Text, } from "react-native";

import { profileIcon } from "./assets";
import { AboutWorkOpp, ProposalComponent, NoResultFound } from "../../../components/src/ProposalComponent";
import { proposalStyle, webStyle2 } from "./Cfproposalmanagement.web"
const config = require("../../../framework/src/config.js");
import './style.css'
import {  SkillButton } from "./OfferReceived.web";

export interface Response {
  id:number,
  attributes: {

    work_opportunity_id: number | string,
    account_id: number | string,
    title: string,
    rate_type: string,
    hourly_rate: string | number,
    project_timeline: string | number,
    project_timeline_type?: string,
    description: string,
    name: string,
    location: string,
    posted_time_ago: string
  }
}
export const styles = {
  container: {
    paddingTop: 24,
  },

  separator: {
    width: 24
  },

  itemHeadingText: {
    color: "#fff2e3",
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: 0.38,
    fontFamily: "Inter-SemiBold,sans-serif"
  },

  marginRate: {
    marginRight: 16
  },

  itemRateDuration: {
    backgroundColor: "#1d1d1d",
    borderRadius: 24,
    paddingVertical: 6,
    paddingHorizontal: 16,
    height: 29,
    textAlign: "center",
  },
  itemRateText: {
    color: "#1CBAE0",
    fontSize: 14,
    lineHeight: 16.94,
    fontFamily: "Inter-SemiBold,sans-serif",
    width: '131px',
    height: "28px"
  },
  itemDurationText: {
    color: "#FFB9C7",
    fontSize: 14,
    lineHeight: 16.94,
    fontFamily: "Inter-SemiBold,sans-serif"
  },

  itemImage: {
    width: 44,
    height: 44,
    borderRadius: 22,
    marginRight: 8
  },
  itemUserNameText: {
    color: "#fff2e3",
    fontFamily: "Inter-SemiBold,sans-serif",
    fontSize: 14,
    lineHeight: 16.94,
    marginBottom: 9
  },
  itemUserLocationText: {
    color: "#fff2e3",
    fontFamily: "Inter,sans-serif",
    fontSize: 12,
    lineHeight: 14.52
  },

}

class UnderReviewProposal extends UnderReviewController {
  formatToLocaleString = (data: Response) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
  getProfileDetailsRenderItem = (proposalData:any) => {
    return proposalData?.attributes?.profile_image && proposalData?.attributes?.profile_image?.includes("rails") ?
      <img src={config.baseURL + proposalData.attributes.profile_image} style={styles.itemImage} alt="Default Profile" /> :

      (proposalData?.attributes?.account_details?.background_color? (

        <div
          style={{...styles.itemImage, backgroundColor: proposalData?.attributes?.account_details?.background_color }}
        />
      ) : (
        <img src={profileIcon} style={styles.itemImage}  alt="Default Profile" />
      ));
  };
  getProfileDetails = () => {
    return this.state.proposalData?.attributes?.profile_photo ?
      <img src={config.baseURL + this.state.proposalData.attributes.profile_photo} className="img-view-preview" alt="Default Profile" /> :

      (this.state.proposalData?.attributes?.background_color ? (

        <div
          className="img-view-preview"
          style={{ backgroundColor: this.state.proposalData?.attributes.background_color }}
        />
      ) : (
        <img src={profileIcon} className="img-view-preview" alt="Default Profile" />
      ));
  };
  formatProjectTimeline = (data: Response) => {
    return `${data.attributes?.project_timeline} ${data.attributes?.project_timeline_type || "Months"}`;
  }
  handleVisibility = (data: string, location?: string) => {
    return data && data.trim() ? <Text style={location ? styles.itemUserLocationText : styles.itemUserNameText}>{data}</Text> : null
  }

  ItemSeparator = () => <View style={styles.separator} />;
  
  handleOpen = (id: number) => {
    this.setState({ pendingListId: id ,accordianPop:false});
    this.getPendingDeailsAPI(id);
  };
  handleRenderItemSubHeading=(item:any)=>{
if(this.props.tabNo==2){
 return    <>
 <Text style={{
        color: "#66625C",
        fontFamily: "Inter,sans-serif",
        fontSize: 13,
        lineHeight: 13.31,
        alignSelf: "flex-start"
      }}>{item?.attributes?.status} {item?.attributes?.decline_time	}</Text>
            <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '10px' }} />

</>
}
  }
  
  renderItem: any = ({ item }: any) => {
    return (
      <Box className={this.state.pendingListId == item?.id ? "active-pending-box" : "pending-box"}
        flexDirection={"column"} boxSizing={"border-box"} data-testId="workOppertunityModal" onClick={() => this.handleOpen(item.id)}
      >
      { this.handleRenderItemSubHeading(item)
            }
        <View style={{
          display: "flex",
          flexDirection: "column"
        }} >
          <Text style={styles.itemHeadingText} data-testId="worktitle" >{item?.attributes?.title}</Text>
          <Box style={{
            display: "flex",
            marginTop: 24,
            flexWrap:'wrap',
            gap:'4px',
            flexDirection: "row"
          }}>
            <View style={{ ...styles.itemRateDuration, ...styles.marginRate }}>
              <Text style={styles.itemRateText}>Rs.{item.attributes?.rate_amount && item.attributes?.rate_amount.toLocaleString('en-IN')} {this.formatToLocaleString(item)}</Text>
            </View>
            <View style={styles.itemRateDuration}>
              <Text style={styles.itemDurationText}>{this.formatProjectTimeline(item)}</Text>
            </View>
          </Box>
          <Text style={{
            fontSize: 12,
            lineHeight: 16.02,
            marginTop: 24,
            color: "#fff2e3",
            fontFamily: "Inter,sans-serif",
            overflow: 'hidden',
          }} numberOfLines={2} ellipsizeMode="tail">
            {item.attributes?.description}
          </Text>
        </View>
        <div data-testId="NavigatetoPublic" >
          <View style={{
            marginTop: 16,
            borderTopWidth: 1.05,
            borderTopColor: "#3E3E3E",
            borderStyle: "solid",
            paddingTop: 11,
            display: "flex",
            justifyContent: "space-between" as 'space-between',
            width: "100%",
            flexDirection: "row" as 'row',
          }}>
            <Box style={{
              display: "flex",
              marginTop: 8,
              flexDirection: "row",
              alignItems: "center", width: '50%'
            }} onClick={()=>{this.handleNavigateFind(item.attributes)}}>
              {this.getProfileDetailsRenderItem(item)}
              <View style={{ display: "flex", width: '60%' }}>
                {this.handleVisibility(item.attributes?.first_name)}
                {this.handleVisibility(item.attributes?.account_details?.user_location, "location")}
              </View>
            </Box>
            <Text style={{
              color: "#66625C",
              fontFamily: "Inter,sans-serif",
              fontSize: 11,
              lineHeight: 13.31,
              alignSelf: "flex-end"
            }}>{item.attributes?.Posted_time_ago}</Text>
          </View>
        </div>
      </Box>
    )
  };
 


  render() {
    return (
      <>
        {this.state.loading ?
          <Box style={{ width: '100%', marginTop: '10%' }}>
            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress className="gray_color" size={25} />
            </Box>
            <Typography className="gray_color" style={{ marginTop: '20px', textAlign: 'center' }}>Searching...</Typography>
          </Box>
          :
          <Grid container style={{justifyContent:"space-between"}} spacing={1}>
            {this.state.pendingList.length>0 ?
              <>
                <Grid item xs={12} sm={4} md={4} lg={3} style={{height: '1800px',
  overflow: 'auto',
  scrollbarWidth: 'none'}}>
                  <View style={styles.container}>
                    <FlatList

                      data-test-id="flat-list"
                      data={this.state.pendingList}
                      renderItem={this.renderItem as any}
                      keyExtractor={(item: any) => item.id}
                      horizontal={false}
                      showsHorizontalScrollIndicator={false}
                      contentContainerStyle={{ display: 'flex', flexDirection: 'column' }}
                      ItemSeparatorComponent={this.ItemSeparator}
                      showsVerticalScrollIndicator={true}

                    />
                  </View>
                </Grid>
                <Grid item xs={8} sm={8} md={8} lg={9} className="about-under-review-second-box">
                  <Box style={{ padding: '40px 40px 0px 40px' }}>

                    <AboutWorkOpp
                      workOppertunityData={this.state.workOppertunityData}
                      SkillButton={SkillButton}
                      handleAbutWorkOpen={this.handleAbutWorkOpen}
                      accordian={this.state.accordianPop}
                      getRateTypeLabelProposal={this.getRateTypeLabelProposal(this.state.workOppertunityData)}
                    />
                  </Box>
                  <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '30px' }} />
                  <Box >
                    <Box style={{ paddingInline: '40px' }}>

                      <Typography style={{ ...proposalStyle.secondHeadingStyle, marginBottom: '40px' }}>
                        Shared proposal
                      </Typography>
                      <Box className="profile-view-preview">
                        {this.getProfileDetails()}
                        <Box className="profile-name-preview2">
                          <Typography className="received-shared-style">
                            {this.state.proposalData?.attributes?.received_time_ago}
                          </Typography>
                          <Typography className="profile-name-preview">
                            {this.state.proposalData?.attributes?.user_name}
                          </Typography>
                          <Typography className="profile-address-preview">
                            {this.state.proposalData?.attributes?.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Grid container spacing={4} style={{ marginBottom: '20px',flexWrap:'wrap' }}>
                        <Grid item xs={12} sm={12} md={7} lg={6} style={{ display: 'flex', flexDirection: 'row' }}>

                          <Box className="part-sub-preview2" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                              Views
                            </Typography>
                            <Typography className="part-sub-value">
                              {this.state.proposalData?.attributes?.views}

                            </Typography>
                          </Box>
                          <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                              Likes
                            </Typography>
                            <Typography className="part-sub-value">
                              {this.state.proposalData?.attributes?.likes}


                            </Typography>
                          </Box>
                          <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                              Service rate
                            </Typography>
                            <Typography style={{ ...webStyle2.priceHour, height: 'unset',width:'100%',padding:'6px 8px' }}>
                              Rs.{" "} {this.state.proposalData?.attributes?.hourly_rate &&this.state.proposalData?.attributes?.hourly_rate?.toLocaleString('en-IN')}{" "}
                              {this.formatToLocaleString(this.state.proposalData)}
                            </Typography>
                          </Box>
                          <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                              Earnings
                            </Typography>
                            <Typography className="part-sub-value">
                              {'₹ 0k'}

                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} lg={6} style={{ display: 'flex', 
                        height:'128px',
                        overflow:"auto",
                        scrollbarWidth:'none',
                          flexDirection: 'row', flexWrap: 'wrap', gap: '16px', paddingLeft: '20px' }}>
                          {this.state.proposalData?.attributes?.skills && this.state.proposalData?.attributes?.skills.length > 0 && this.state.proposalData?.attributes?.skills.map((item: any) => {


                            return <Box className="chips-preview">
                              {item.name}
                            </Box>
                          })
                          }
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '30px' }} />
                    <Box style={{ paddingInline: '40px' }}>

                      <ProposalComponent
                        projectEstimate={this.state.proposalData?.attributes?.overall_project_estimate}
                        previewData={this.state.proposalData?.attributes?.projects}
                        showCollection={this.state.proposalData?.attributes?.projects.length == 0 ? true : false}
                        pitchData={this.state.proposalData?.attributes?.give_your_pitch}
                        hourlyRate={this.state.proposalData?.attributes?.hourly_rate}
                        dateData={this.state.proposalData?.attributes?.start_date}
                        isFileAdded={this.state.proposalData?.attributes?.resource_files.length > 0}
                        linkList={this.state.proposalData?.attributes?.links ?? ""}
                        files={this.state.proposalData?.attributes?.resource_files}
                        openImageInNewTab={(file: string) => {
                          const newTab = window.open(config.baseURL + file, '_blank');
                          if (newTab) {
                            newTab.focus();
                          }
                        }}
                        
                        workOppertunityData={this.state.workOppertunityData}
                        Question={this.state.proposalData?.attributes?.answer || []}
                        project_timeline={this.state.proposalData?.attributes?.work_timeline}
                        project_timeline_type={this.state.proposalData?.attributes?.work_timeline_type}
                        hours={this.state.proposalData?.attributes?.required_hours_per_week}
                        rateHourlyClient={this.state.workOppertunityData?.attributes?.rate_amount}
                        rateType={this.getRateTypeLabelProposal(this.state.proposalData)}
                        findWork={true}
                      />
                    </Box>
                  </Box>
                  <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '30px' }} />
                  <StepperStyledComponent activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper">
                    {this.state.proposalData?.attributes?.proposal_history?.data.map((label: {
                      "id": string
                      "type": string
                      "attributes": {
                        "id": number,
                        "proposal_id": number,
                        "event": string,
                        "event_date": string
                        "ref_type": string
                        "ref_id": number
                      }
                    }) => (
                      <Step key={label?.attributes?.event}>
                        <StepLabel >
                          <Typography>

                            {label?.attributes?.event}
                          </Typography>
                          <Typography>

                            {label?.attributes?.event_date}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </StepperStyledComponent>
                </Grid>
              </> :
              <NoResultFound />
            }
          </Grid>
        }
      </>
    );
  }
}

export const AccordianSummaryComponent=styled(AccordionSummary)({
  '& .MuiAccordionSummary-content':{
    margin:'0px'
  },
  '&.MuiAccordionSummary-root':{
    minHeight:'12px'
  }

})
export const StepperStyledComponentWithNumber = styled(Stepper)({
  marginBottom: '20px',
  '& .step-with-line  .MuiStepContent-root': {
    paddingLeft: '45px',
    marginTop: '0px',
    borderLeft: '2px solid rgba(73, 73, 73, 1)'
  },
  '& .MuiStepContent-root': {
    paddingLeft: '44px',
    paddingRight: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-root':{
    alignItems: 'flex-start'
  },
  '& .MuiStepIcon-text':{
fill:"rgba(17, 17, 17, 1)"
  },
  '& .step-with-line .MuiStepLabel-labelContainer': {
    marginLeft: '20px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-46px',
      top: 30,
      bottom: 0,
      width: '2px',
      backgroundColor: 'rgba(73, 73, 73, 1)',
      height: '100%',
    },
  },
  '& .MuiStepLabel-labelContainer': {
    marginLeft: '20px',
    position: 'relative',
  },
  '& .MuiStepLabel-iconContainer .MuiSvgIcon-root':{
    width:'30px',
    height:'30px'
  },
  '& .MuiStepLabel-label.MuiStepLabel-active': {
    color: "rgba(255, 242, 227, 1)",
    justifyContent: 'space-between',
    width: '100%'
  },
  '& .MuiStepLabel-label': {
    color: "rgba(255, 242, 227, 1)",
    width: '100%'
  },
  '& .MuiStepIcon-root.MuiStepIcon-active': {
    color: "rgba(255, 242, 227, 1)"

  },
  '& .MuiStepIcon-root': {
    color: "rgba(255, 242, 227, 1)"

  },
  '& .MuiStepConnector-lineVertical': {
    minHeight: '70px',
    borderLeftWidth: '2px'

  },
  '& .MuiStepConnector-line': {
    borderColor: "rgba(73, 73, 73, 1)"
  },
  '& .MuiStepConnector-vertical': {
    padding: '0px',
  }
})

export const StepperStyledComponent = styled(Stepper)({
  marginBottom: '20px',
  '& .MuiStepLabel-label.MuiStepLabel-active': {
    color: "rgba(255, 242, 227, 1)",
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%'
  },
  '& .MuiStepLabel-label': {
    color: "rgba(255, 242, 227, 1)",
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%'
  },
  '& .MuiStepIcon-root.MuiStepIcon-active': {
    color: "rgba(255, 242, 227, 1)"

  },
  '& .MuiStepIcon-root': {
    color: "rgba(255, 242, 227, 1)"

  },
  '& .MuiStepConnector-lineVertical': {
    minHeight: '50px',
    borderLeftWidth: '2px'

  },
  '& .MuiStepConnector-line': {
    borderColor: "rgba(255, 242, 227, 1)"
  },
  '& .MuiStepConnector-vertical': {
    padding: '0px'
  }
})
export default UnderReviewProposal;
// Customizable Area End
