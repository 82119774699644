Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.messagesHeading = "Messages";
exports.searchPlaceholder = "Search";
exports.dotSymbol = ".";
exports.onlineText = "Online";
exports.offlineText = "Offline";
exports.newText = "New";
exports.typeMessagePlaceholder = "Type message";
exports.newMessage = "New message";
exports.suggestions = "Suggestions";
exports.intiateConversationText = "Initiate Conversation: Compose Now";
exports.startConversationTextOne = "Start a conversation, connect, and engage by composing your first message right away.";
exports.imageVideoType = "video/mp4, video/x-msvideo, video/webm, video/quicktime, video/x-matroska, video/x-flv, video/3gpp, video/3gpp2, video/x-ms-wmv, video/mpeg, video/ogg,image/jpeg, image/png, image/gif, image/bmp, image/webp, image/svg+xml, image/tiff, image/x-icon, image/avif";
exports.composeText = "Compose";
exports.tabsData = [
  {
    tabName: "All",
    tabId: "all",
    count: 0,
  },
  {
    tabName: "Contracts",
    tabId: "contracts",
    count: 0,
  },
  {
    tabName: "Inquiries",
    tabId: "inquiries",
    count: 0,
  }
];
exports.startConversationText = "Start a new conversation."
exports.photosAndVideosText = "Photos & videos ";
exports.documentText = "Document";
exports.errorMessage = 'An unexpected error occurred.' 
exports.createChannelEndpoint = "bx_block_chat/users/create_channel";
exports.listUserEndPoint = "/bx_block_chat/users/list_user";
exports.channelListEndPoint = "/bx_block_chat/users/my_group_channel";
exports.unreadCountEndPoint = "/bx_block_chat/users/get_unread_channel_count";
exports.baseUrl = require("../../../framework/src/config").baseURL;
exports.clearChattext="Clear Chat"
exports.deleteChattext="Delete Chat";
exports.noUserListFound = "No suggested users found.";
exports.noMessagesText = "No Messages";
exports.yesetdayText = "Yesterday";
exports.onGoingContract = "Ongoing contract";
exports.companyName = "Logo Design for Pharma company";
  // Customizable Area End