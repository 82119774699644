// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData,setStorageData} from "framework/src/Utilities";
export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}
export interface Itabs {
  label:string
}

export interface ParticularCollection
		{
			"id": string,
			"type": string,
			"attributes": {
				"project_name": string,
				"start_date": null | Date,
				"end_date": null | Date,
				"url": null | string,
				"description": string,
				"username": string,
				"account_id": number,
				"profile_photo": string,
				"cover_image_url": string,
				"project_images": string[],
				"likes": number,
				"views": number,
				"saved_count": number,
				"collection_name": string,
				"is_liked": boolean,
				"is_saved": boolean
			}
}

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  EditProfile:boolean;
  handleCloseEditProfile:any;
  
}
interface ListType {
  id: string | number,
  name: string
}

interface S {
  companyOrganization:string;
  companyError:string;
  positionError:string;
  position:string;
  selectedRegion:string;
  regionError:string;
city:string;
CityError:string;
startyear:any;
endYear:any;
endMonth:string;
startMonth:string;
endYearError:string;
details:string;
referencelinks: string;
links: any,
linkError:string;
currentlyWorking:boolean;
token:string;
expertiseTempData:any;
filteredOptionsExpertise:any;
enteredExpQuery:any;
skillsSelected:any;
collectionList:any;
currentCollectionId:any;
currentPage:number;
totalCount:number;
particularCollectionData:ParticularCollection[],
tempSelectedProjectList: string[],
selectedProjectList: ParticularCollection[],
previewData:any;
viewMore:boolean;
ischecked:boolean;
toastStatusexp:boolean;
toastMsgExp:string;
toastActionExp:string;
  AllStates:any;
  address:string;
  country:string;
  selectedSkillTags: ListType[],
  skillList: ListType[],
  searchText:string, 
  searchSkillList: ListType[],
  categoryList: ListType[],
  


}

interface SS {
  id: any;
}

export default class WorkExperienceFromController extends BlockComponent<
  Props,
  S,
  SS
> {
  delayTimer: any;
  baseUrlStirng:string = configJSON.baseURL.baseURL;
  collectionDataID:string='';
  collectionNameGetApiCallId:string='';
  apiSearchSkillsCallId: string = "";
  postAddProfessionalWorkExpApiCallId:string=''
  apiCategoryCallId:string='';
  apiSkillsCallId:string=''

  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
    companyOrganization:'' ,
    companyError:'' ,
    positionError:'',
    position:'' , 
    regionError:'',  
    selectedRegion:'',
    city:'',
    CityError:'',
    startyear:null,
    endYear:null,
    endMonth:'',
    startMonth:'',
    endYearError:'',
    details:'',
    referencelinks: "",
    links: [],
    linkError:'',
    ischecked:false,
    currentlyWorking:false,
    token:'',
    viewMore:false,
    expertiseTempData:[],
    filteredOptionsExpertise:[],
    selectedSkillTags: [],
    enteredExpQuery:'',
    skillsSelected:[],
    collectionList:[],
    currentCollectionId:0,
    currentPage:0,
    particularCollectionData:[],
    totalCount:0,
    tempSelectedProjectList: [],
    selectedProjectList:[],
    previewData:[],
    address:'',
    country:'',
    toastStatusexp:false,
    toastMsgExp:'',
    toastActionExp:'',
    skillList: [],
    searchText:'',
    searchSkillList: [],
    categoryList: [],
    AllStates:[{"code": "1","name": "AnyWhere/Remote"},
      {"code": "2","name": "All/Countries"}]
    };
    this.delayTimer = null;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
   
    if (apiRequestCallId1 === this.apiCategoryCallId) {
      this.handleCategoryList(message);
    }
   if (apiRequestCallId1 === this.apiSkillsCallId) {
      this.handleSkillsList(message);
    }
     if (apiRequestCallId1 === this.apiSearchSkillsCallId) {
      this.handleSearchSkillsList(message);
    }
    if (apiRequestCallId1 === this.collectionNameGetApiCallId) {
      if (responseJson1?.data?.collections) {
        this.setState({
          collectionList: responseJson1.data.collections,
          currentCollectionId: responseJson1.data.collections[0].id
        }, () => {
          this.collectionData(responseJson1.data.collections[0].id); 
        });
      }
      
    }
    if (apiRequestCallId1 === this.collectionDataID) {
      this.setState({
        particularCollectionData:responseJson1?.data,
        totalCount:Math.ceil(responseJson1?.meta?.project_count/6)
      });
    }

    this.handlepostformdetails(apiRequestCallId1,responseJson1)
    
    
  }

  handlepostformdetails=(apiRequestCallId1:string,responseJson1:any)=>{
    if (apiRequestCallId1 === this.postAddProfessionalWorkExpApiCallId ) {
     
      if (!responseJson1.errors && !responseJson1.error) {
        this.setState({toastStatusexp:true,toastActionExp:"success",toastMsgExp:"sucessfully Added"})
        this.props.handleCloseEditProfile()
      }
      if(responseJson1.errors) {
        this.setState({toastStatusexp:true,toastActionExp:"danger",toastMsgExp:"please fill the mandatory fields"})
      }
    }
  }

  handleSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ skillList: [...dataVal.map((item: {id: string | number, name: string}) => ({id: item.id, name: item.name }))]});
      }
    } 
  }
  handleSearchSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ searchSkillList: dataVal.map((item: {id: string | number, name: string}) => ({ id: item.id, name: item.name })) });
      }
    } 
  }
  handleChangess = (data: string) => {
    this.setState({ searchText: data });
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      if (data.trim() !== '' && data.trim().length >= 2) {
        this.search(data.trim());
      } else {
        this.setState({ searchSkillList: [] })
      }
    }, 300); 
  };

  search = (data: string) => {

    this.commonGetApiCall('searchSkills', `${configJSON.searchSkillEndPoint}?search_term=${data}`);
  };


 
  
  handleToastClose = () => {
    this.setState({ toastStatusexp: false });
  }

  handleChangeLocationText = (data: string) => {
    this.setState({city:data,address:data})
  }

  handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) =>{
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleChangeLocation = (data: string) => {
    let values = data.split(',')
    
    this.setState({address:data, city:values[0], country:values[values.length - 1]})
}

handleChangeLocationTextBlur = () => {
  if(!this.state.city){
    this.setState({address:""})
  }
}
  

  handleCollection=(id:number|null)=>{
    this.setState({currentCollectionId:id,currentPage:1})
    this.collectionData(id)
  }
  
  handleEndYear=()=>{
    this.setState({ischecked:!this.state.ischecked},()=>this.validateEndYear())
    
  }

  validateEndYear = () => {

    const { startyear, endYear,startMonth,endMonth,ischecked } = this.state;
    
    if(ischecked) {
      this.setState({endYearError:''})
    }
    if (!ischecked) {
       if (startyear > endYear) {
        this.setState({ endYearError: 'End year must not be smaller than start year' });
      } 
      
      else if (startyear === endYear) {
       
        const startMonthIndex = this.getMonthList().findIndex(month => month.label === startMonth);
        const endMonthIndex = this.getMonthList().findIndex(month => month.label === endMonth);
        
        if (startMonthIndex > endMonthIndex) {
          this.setState({ endYearError: 'Start month must not be greater than end month when the years are the same.' });
        } else {
          this.setState({ endYearError: '' });
        }
      } 
      else {
       
        this.setState({ endYearError: '' });
      }
    }
   
  };

  getCollectionAPI = async () => {
    const tokens = await getStorageData("userInfo");
    let newToken = JSON.parse(tokens)
    const { meta: { token ,id} } = newToken;  
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_projectportfolio/get_user_collections' )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionNameGetApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }  



  handleAddSkillTags = (data: ListType) => {
   
    this.setState({ selectedSkillTags: [data, ...this.state.selectedSkillTags], skillList:  this.state.skillList.filter(item => item.id !== data.id)});
  }

  handleRemoveSkillTags = (data: ListType) => {
    this.setState({ selectedSkillTags: this.state.selectedSkillTags.filter(item => item.id !== data.id), skillList: [data, ...this.state.skillList]});
  }



  handleCategoryList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      const dataVal = responseJson.data || '';
      if (dataVal) {
        this.setState({ categoryList: [...dataVal.map((item: {id: string | number, name: string}) => ({id: item.id, name: item.name }))]});
      }
    }
  }
  
  collectionData= async (collectionId:number|null)=>{
    const tokens = await getStorageData("userInfo");   
  let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken;   
    const { meta: { id } } = newToken;   
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_portfolio_management/portfolios/get_projects_based_collection?collection_id=${collectionId}&account_id=${id}&page=${this.state.currentPage}&per_page=6`)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'GET'
    );
    this.collectionDataID = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  async getDataList() {
    this.commonGetApiCall('category', configJSON.getCategoriesList);
    this.commonGetApiCall('skills', configJSON.getSkillEndPoint);
  }

  handleClearText =()=>{
    this.setState({searchText:'',searchSkillList:[]})
  }
  commonGetApiCall = async (variableName: string, urlEndPoint: string) => {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    this.setState({token:token})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (variableName === "category") {
      this.apiCategoryCallId = requestMessage.messageId;
    }
    if (variableName === 'searchSkills') {
      this.apiSearchSkillsCallId = requestMessage.messageId;
    }
    
    if (variableName === "skills") {
      this.apiSkillsCallId = requestMessage.messageId;
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  handleSelectAll = () => {
    const { particularCollectionData, tempSelectedProjectList, selectedProjectList } = this.state;
  
    if (this.areAllProjectsSelected()) {
      const updatedTempList = tempSelectedProjectList.filter(id => !particularCollectionData.some(item => item.id === id));
      const updatedSelectedList = selectedProjectList.filter(project => !particularCollectionData.some(item => item.id === project.id));
  
      const groupedProjects = updatedSelectedList.reduce((acc: any, project) => {
        const collectionName = project.attributes.collection_name;
        if (!acc[collectionName]) {
          acc[collectionName] = {
            collection_name: collectionName,
            data: [],
          };
        }
        acc[collectionName].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        tempSelectedProjectList: updatedTempList,
        selectedProjectList: updatedSelectedList,
        previewData: Object.values(groupedProjects),
      });
    } else {
      const newIds = particularCollectionData.map(item => item.id);
      const updatedSelectedList = [...new Set([...selectedProjectList, ...particularCollectionData])];
      const updatedTempList = [...new Set([...tempSelectedProjectList, ...newIds])];
  
      const groupedProjectsData = updatedSelectedList.reduce((acc: any, project) => {
        const collectionNameData = project.attributes.collection_name;
        if (!acc[collectionNameData]) {
          acc[collectionNameData] = {
            data: [],
            collection_name: collectionNameData,
          };
        }
        acc[collectionNameData].data.push(project.attributes);
        return acc;
      }, {});
  
      this.setState({
        previewData: Object.values(groupedProjectsData), 
        selectedProjectList: updatedSelectedList,
        tempSelectedProjectList: updatedTempList,
      });
    }
  };

  areAllProjectsSelected = () => {
    const { particularCollectionData, tempSelectedProjectList } = this.state;
    return particularCollectionData&& particularCollectionData.length>0 ? 
    particularCollectionData.every(item => tempSelectedProjectList.includes(item.id)):
    false;
  };

  handleareAllProjectsSelected=()=>{
    return this.areAllProjectsSelected() ? "Deselect all" : "Select all"
  }

  toggleSelectProject = (item: ParticularCollection) => {
    const { tempSelectedProjectList, selectedProjectList } = this.state;
    const isAlreadySelected = tempSelectedProjectList.includes(item.id);
  
    let updatedTempList, updatedSelectedList;
  
    if (isAlreadySelected) {
      updatedTempList = tempSelectedProjectList.filter(projectId => projectId !== item.id);
      updatedSelectedList = selectedProjectList.filter(project => project.id !== item.id);
    } else {
      updatedTempList = [...tempSelectedProjectList, item.id];
      updatedSelectedList = [...selectedProjectList, item];
    }
  
    const groupedProjectsParticular = updatedSelectedList.reduce((acc: any, project) => {
      const collectionNameParticular = project.attributes.collection_name;
      if (!acc[collectionNameParticular]) {
        acc[collectionNameParticular] = {
          data: [],
          collection_name: collectionNameParticular,
        };
      }
      acc[collectionNameParticular].data.push(project.attributes);
      return acc;
    }, {});
  
    this.setState({
      selectedProjectList: updatedSelectedList,
      previewData: Object.values(groupedProjectsParticular), 
      tempSelectedProjectList: updatedTempList,
    });
  };
  



handleChanges=(event:any,page:any)=>{
  this.setState({currentPage:page})
}

handleViewCheck = (item: ParticularCollection) => {
  const matchedData = this.state.tempSelectedProjectList.includes(item.id);
  return matchedData ? true : false
}






  async componentDidMount() {
this.getCollectionAPI()
this.getDataList();
    
  }


  getYearList=()=>{
    const years: any = [];
    const startYear = 1960;
    const endYear = new Date().getFullYear();
    
   
    years.push(endYear);

    
    for (let year = endYear - 1; year >= startYear; year--) {
        years.push(year);
    }

    return years;
  }

  getMonthList = () => {
    const months = [
        { value: 1, label: "January" },
        { value: 2, label: "February" },
        { value: 3, label: "March" },
        { value: 4, label: "April" },
        { value: 5, label: "May" },
        { value: 6, label: "June" },
        { value: 7, label: "July" },
        { value: 8, label: "August" },
        { value: 9, label: "September" },
        { value: 10, label: "October" },
        { value: 11, label: "November" },
        { value: 12, label: "December" }
    ];
    return months;
};

  handlecheckbox = (e:any) =>{
   this.setState({currentlyWorking:e.target.checked})
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement| HTMLTextAreaElement>) => {
    const {  name, value  } = e.target;
   
    
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }),()=>{this.validateEndYear()}
  )
 
  };

  viewMoreForExpertise=(viewMore:any)=>{
    return viewMore?"View less":"View more..."
  }
  handleAddLink = () => {
    const { referencelinks, links } = this.state;

    if (referencelinks.trim() === "") {
        this.setState({ linkError: "Please enter a link." });
        return;
    }

    const urlPattern = /^(https?:\/\/)?([\w.-]+)([\/\w .-]*)*\/?$/;
    if (!urlPattern.test(referencelinks)) {
        this.setState({ linkError: "Please enter a valid URL." });
        return;
    }

    if (links.includes(referencelinks.trim())) {
        this.setState({ linkError: "This link has already been added." });
        return;
    }

    this.setState({
        links: [...links, referencelinks.trim()], 
        referencelinks: "", 
        linkError: "", 
    });
};
  handleBlur = (field:string) => {
    if (field === 'companyOrganization') {
      this.setState({
        companyError: this.state.companyOrganization ? '' : 'Company Organization is required.'
      });
    } else if (field === 'position') {
      this.setState({
        positionError: this.state.position ? '' : 'Position is required.'
      });
    }else if (field === 'region') {
      this.setState({
        regionError: this.state.selectedRegion ? '' : 'location is required.'
      });
      
    }
    else if (field === 'city') {
      this.setState({
        CityError: this.state.city ? '' : 'location is required.'
      });
    }
  };

  
  
  hasErrors = () => {
   let {companyOrganization,position,details,startyear}=this.state
    return (

     !!companyOrganization && !!position && !!details && !!startyear
      
    );
  };

  handleFormData =()=>{
    const formdata = new FormData();
    const { selectedSkillTags } = this.state;

    let skills = selectedSkillTags.map((data:any)=>data.id)

  let projectID = this.state.selectedProjectList.map((data:any)=>data.id)
formdata.append("professional_experience[company_name]", this.state.companyOrganization);
formdata.append("professional_experience[position]", this.state.position);
formdata.append("professional_experience[location]", this.state.selectedRegion);
if(this.state.selectedRegion === 'All/Countries') {

  formdata.append("professional_experience[city]", this.state.city);
}else {
  formdata.append("professional_experience[city]", 'Remote');
}
formdata.append("professional_experience[starting_from_month]", this.state.startMonth);
formdata.append("professional_experience[starting_from_year]", `${this.state.startyear}`);
if (!this.state.ischecked) {

  formdata.append("professional_experience[ending_in_month]", this.state.endMonth);
  formdata.append("professional_experience[ending_in_year]", `${this.state.endYear}`);
}
formdata.append("professional_experience[details]", this.state.details);
formdata.append("professional_experience[reference_links]",this.state.links );
formdata.append("professional_experience[skill_ids]", `${skills}`);
formdata.append("professional_experience[project_ids]", `${projectID}`);

return formdata

  }

  handleSaveChanges =async () =>{
   
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const {meta: { token } } = newToken;
    const header = {
      "token":token
    };
    
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.postAddProfessionalWorkExpApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddProfessionalWorkExp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      this.handleFormData()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  HandleBackBtn =()=>{
    this.props.handleCloseEditProfile()
    this.setState({
      companyOrganization:'' ,
    companyError:'' ,
    positionError:'',
    position:'' , 
    regionError:'',  
    selectedRegion:'',
    city:'',
    CityError:'',
    startyear:'',
    endYear:'',
    endMonth:'',
    startMonth:'',
    details:'',
    referencelinks: "",
    links: [],
    linkError:'',
    currentlyWorking:false,
    token:'',
    viewMore:false,
    expertiseTempData:[],
    filteredOptionsExpertise:[],
    enteredExpQuery:'',
    skillsSelected:[],
    collectionList:[],
    currentCollectionId:0,
    currentPage:0,
    particularCollectionData:[],
    totalCount:0,
    tempSelectedProjectList: [],
    selectedProjectList:[],
    previewData:[],
    })
  }
}

// Customizable Area End
