// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { BuilderProvider } from "@builder/component-library"
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfcalculateopportunitycost from "../../blocks/cfcalculateopportunitycost/src/Cfcalculateopportunitycost";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cf03converttouiw2 from "../../blocks/cf03converttouiw2/src/Cf03converttouiw2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Cfproposalmanagement from "../../blocks/cfproposalmanagement/src/Cfproposalmanagement";
import Imagemanagement2 from "../../blocks/imagemanagement2/src/Imagemanagement2";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cf04converttouiw1 from "../../blocks/cf04converttouiw1/src/Cf04converttouiw1";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ContactList from "../../blocks/contactlist/src/ContactList";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserProfile from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import Cfupdatecontractmilestones from "../../blocks/cfupdatecontractmilestones/src/Cfupdatecontractmilestones";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cf02converttouiw2 from "../../blocks/cf02converttouiw2/src/Cf02converttouiw2";
import Cfwalletmanagement13 from "../../blocks/cfwalletmanagement13/src/Cfwalletmanagement13";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import { NewNotifications } from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cf01converttouiw2 from "../../blocks/cf01converttouiw2/src/Cf01converttouiw2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import ContractWeb from '../../blocks/cfproposalmanagement/src/Contract.web'
import Cfmilestonemanagement1 from "../../blocks/cfmilestonemanagement1/src/Cfmilestonemanagement1";
import { NewAdvancedSearch } from "../../blocks/advancedsearch/src/AdvancedSearch.web";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Cfanalyticsboards from "../../blocks/cfanalyticsboards/src/Cfanalyticsboards";
import { NewCarouselDisplay } from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import { NewChat } from "../../blocks/chat/src/Chat.web";
import ViewChat from "../../blocks/chat/src/ViewChat";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Cfdesignersidecontractmanagement from "../../blocks/cfdesignersidecontractmanagement/src/Cfdesignersidecontractmanagement.web";
import Followers from "../../blocks/followers/src/Followers";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import ShareProject from "../../blocks/dashboard/src/shareproject/ShareProject.web";
import EditProject from "../../blocks/dashboard/src/editProject/EditProject.web";
import PostOpportunity from "../../blocks/dashboard/src/postopportunity/PostOpportunity.web"
import JobListing from "../../blocks/joblisting/src/JobListing.web"
import PostEmailAccountRegistration from "../../blocks/email-account-registration/src/PostEmailAccountRegistration";
import PublicUserProfile from "../../blocks/user-profile-basic/src/PublicUserProfile/PublicUserProfile.web";
import { Proposalgeneration } from "../../blocks/proposalgeneration/src/Proposalgeneration2.web";
import ViewWorkDetail from "../../blocks/joblisting/src/ViewWorkDetail.web"
import ExpoloreMoreOpportunity from "../../blocks/joblisting/src/ExpoloreMoreOpportunity.web" 


const routeMap = {

AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
LinkedInCallback:{
  component:LinkedInCallback,
 path:"/linkedin"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfcalculateopportunitycost:{
 component:Cfcalculateopportunitycost,
path:"/Cfcalculateopportunitycost"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cf03converttouiw2:{
 component:Cf03converttouiw2,
path:"/Cf03converttouiw2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Cfproposalmanagement:{
 component:Cfproposalmanagement,
path:"/Cfproposalmanagement"},
Imagemanagement2:{
 component:Imagemanagement2,
path:"/Imagemanagement2"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cf04converttouiw1:{
 component:Cf04converttouiw1,
path:"/Cf04converttouiw1"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
ContactList:{
 component:ContactList,
path:"/ContactList"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserProfile: {
  component: UserProfile,
  path: "/UserProfile"
},
PublicUserProfile : {
component:PublicUserProfile,
path:"/PublicUserProfile"
},
Cfupdatecontractmilestones:{
 component:Cfupdatecontractmilestones,
path:"/Cfupdatecontractmilestones"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cf02converttouiw2:{
 component:Cf02converttouiw2,
path:"/Cf02converttouiw2"},
Cfwalletmanagement13:{
 component:Cfwalletmanagement13,
path:"/Cfwalletmanagement13"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Notifications:{
 component:NewNotifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
  PostEmailAccountRegistration: {
    component: PostEmailAccountRegistration,
    path: "/PostEmailAccountRegistration"
  },
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cf01converttouiw2:{
 component:Cf01converttouiw2,
path:"/Cf01converttouiw2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
ContractPage:{
  component:ContractWeb,
 path:"/ContractPage"},
Cfmilestonemanagement1:{
 component:Cfmilestonemanagement1,
path:"/Cfmilestonemanagement1"},
AdvancedSearch:{
 component:NewAdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Cfanalyticsboards:{
 component:Cfanalyticsboards,
path:"/Cfanalyticsboards"},
CarouselDisplay:{
 component:NewCarouselDisplay,
path:"/CarouselDisplay"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
Cfdesignersidecontractmanagement:{
 component:Cfdesignersidecontractmanagement,
path:"/Cfdesignersidecontractmanagement"},
Followers:{
 component:Followers,
path:"/Followers"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},

  Home: {
    component: EmailAccountRegistration,
    path: '/',
    exact: true
  },
  FindWork: {
    component: JobListing,
    path: '/FindWork',
    exact: true
  },
  MyWork: {
    component: JobListing,
    path: '/MyWork',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  Proposalgeneration: {
    component: Proposalgeneration,
    path: '/Proposalgeneration/:id',
    exact: true,
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Chat:{
    component:NewChat,
   path:"/Chat"},
  ShareProject: {
    component: ShareProject,
    path: '/ShareProject'
  },
  EditProject: {
    component: EditProject,
    path: '/EditProject/:id'
  },
  PostOpportunity: {
    component: PostOpportunity,
    path: '/PostOpportunity'
  },
  Cfdesignersidecontractmanagement: {
    component: Cfdesignersidecontractmanagement,
    path: '/ContractOffer/:id'
  },
  ViewWorkDetail: {
    component: ViewWorkDetail,
    path: '/ViewWorkDetail/:id/:dropdownId'
  },
  ExploreMore: {
    component: ExpoloreMoreOpportunity,
    path: '/ExploreMore'
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;