// Customizable Area Start
import React from "react";
import ToastMSG from "../../../../components/src/ToastMSG.web";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { dropDownIcon, leftArrowIcon, deleteIcon, editIcon, uploadIcon, searchIcon, closeIcon, shareProject, imgIcon } from "../assets";
import Cropper from "react-easy-crop";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import { Box, Input, Button, Typography, InputLabel, Slider, IconButton, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ShareProjectController, { Props } from "./ShareProjectController";

const customStyle = require('../CustomStyles.js');

const CustomTextField = withStyles({
    root: {
      ...customStyle.customStyle.inputField
    }
})(Input);

const CustomButton = withStyles({
    root: {
        ...customStyle.customStyle.buttonField
    }
})(Button);

const CustomBox = withStyles((theme) => ({
    root: {
        '& .MuiSlider-track': {
            border: 'none',
            height: 5,
            borderRadius:6,
            background: "#FFF2E3"
          },
          '& .MuiSlider-thumb': {
            height: 19,
            width: 19,
            backgroundColor: '#FFF2E3',
            bottom: 3,
            '&:focus, &:hover, &$active': {
              boxShadow: 'inherit',
            },
          },
          '& .MuiSlider-rail': {
            height: 5,
            opacity: 1,
            borderRadius:6,
            backgroundColor: '#626262',
          },
          '& .MuiSlider-valueLabel': {
            display: 'none',
          },
        '&.share-project-container': {
            display: 'flex',
            backgroundColor: 'hsl(0, 0%, 5%)',
            flexDirection: 'column',
            paddingLeft: '48px',
            paddingRight: '48px',
            paddingBottom: '50px',
            height: 'auto',
            '& .select-btn': {
            width: '209px',
            height: '37px',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: '#fff2e3',
            border: '1px solid #66625c',
            borderRadius: '6px',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            lineHeight: '14px',
            letterSpacing: '0.38px',
                '& img.moveup': {
                    transform: 'rotate(180deg)',
                }
            },
            '& .header-container': {
                display: 'flex',
                height: '135px',
                justifyContent: 'space-between'
            },
            '& .left-elm': {
                display: 'flex',
                columnGap: '21px',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& .back-icon': {
                width: '9px',
                height: '17px',
                cursor:"pointer"
            },
            '& .button-elm': {
                width: '155px',
                fontFamily: 'Inter, sans-serif !important'
            },
            '& .cover-label': {
                width: '158px',
                fontFamily: 'Inter-SemiBold, sans-serif !important'
            },
            '& .discard-container': {
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '& .discard-text': {
                    fontSize: '16px',
                    fontFamily: 'Inter-SemiBold, sans-serif',
                    lineHeight: '19.36px',
                    color: '#fff2e3',
                    borderBottom: '1px solid #fff2e3',
                    width: 'max-content',
                    marginLeft: '8.67px',
                }
            },
            '& .right-section': {
                marginTop: '57px',
                display: 'flex',
                alignItems: 'center',
                columnGap: '34.23px',
                height: 'max-content'
            },
            '& .title-container, & .description-container': {
                display: 'flex',
                gap: '8px',
                flexDirection: 'column'
            },
            '& .title-text, & .project-title, & .description-title, & .search-field': {
                color: '#fff2e3',
                fontSize: '16px',
                lineHeight: '19.36px'
            },
            '& .title-text': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                margin: 0
            },
            '& .descriptionError':{
                fontFamily: 'Inter, sans-serif',
                color:"red",
                marginLeft:"10px"
            },
            '& .project-title, & .description-title': {
                border: 'none',
                backgroundColor: '#222',
                borderRadius: '6px',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontFamily: 'Inter, sans-serif',
                display: 'inline-block'
            },
            '& .description-title': {
                padding: '15px 20px',
                height: '51px',
                resize: 'none',
                scrollbarWidth: 'none'
            },
            '& .project-title': {
                height: '51px',
                padding: '10px 20px',
            },
            '& .flex-elm': {
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            },
            '& .cover-container': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            '& .project-title .MuiInputBase-input::placeholder, & .description-title .MuiInputBase-input::placeholder, & .search-field .MuiInputBase-input::placeholder': {
                color: '#686868 !important',
                fontStyle: 'italic !important',
                opacity: 1
            },
            '& .heading-text': {
                fontSize: '36px',
                lineHeight: '43.57px',
                color: '#fff2e3',
                fontFamily: 'Inter-SemiBold, sans-serif'
            },
            '& .option-container-category .menu-item, & .option-container-collection .menu-item': {
                color: '#fff2e3',
                cursor: 'pointer',
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                lineHeight: '19.36px',
                padding: '0 16px',
                display: 'flex',
                alignItems: 'center',
                height: '40px',
                borderTop: '1px solid #5f5f5f',
                zIndex: 12,
                backgroundColor: '#171717',
                '&.active': {
                    backgroundColor: '#222',
                    color: '#61CA78',
                }
            },
            '& .option-container-category, & .option-container-collection': {
                borderRadius: '0 0 10px 10px',
                border: '1px solid #66625c',
                borderTop: 'none',
                position: 'absolute',
                width: '207px',
                maxHeight: '205px',
                overflow: 'auto',
                zIndex: 12,
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                    width: '0',
                    height: '0',
                }
            },
            '& .menu-item:last-child': {
                borderRadius: '0 0 10px 10px'
            },
            '& .hint-text': {
                fontSize: '16px',
                lineHeight: '19.36px',
                fontFamily: 'Inter, sans-serif',
                color: '#686868',
                marginTop: '44px'
            },
            '& .cover-image-container': {
                width: '415px',
                height: '286px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                border: '1px dashed #8C8C8C',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                marginLeft: 'auto',
                marginTop: '15px'
            },
            '& .no-border': {
                border: 'none !important'
            },
            '& .profile-image-container': {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .profile-label': {
                marginTop: '22px',
                width: '143px'
            },
            '& .drag-text': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '20px',
                lineHeight: '24.2px',
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: 'max-content',
                marginBottom: '29px'
            },
            '& .profile-upload-container': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff2e3',
                fontSize: '20px',
                lineHeight: '24.2px',
                backgroundColor: '#222',
                fontFamily: 'Inter, sans-serif'
            },
            '& .profile-image-container, & .profile-upload-container': {
                minHeight: '600px',
                maxHeight: '852.74px',
                borderRadius: '23.3px'
            },
            '& .right-container': {
                width: 'calc((100% - 36px) * 1/3)'
            },
            '& .form-container': {
                display: 'flex',
                columnGap: '36px'
            },
            '& .delete-profile': {
                display: 'flex',
                width: '41px',
                height: '41px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#312f2f',
                cursor: 'pointer',
                marginRight: '19px',
                marginLeft: 'auto',
                borderRadius: '50%'
            },
            '& .menu-open': {
                borderRadius: '10px 10px 0 0',
                height: '40px',
                border: '1px solid #5f5f5f',
                borderBottom: 'none'
            },
            '& .select-container': {
                position: 'relative'
            },
            '& .search-container': {
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                paddingLeft: '22px',
                paddingRight: '22px'
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderBottom: "none !important"
            },
            '& .search-field': {
                width: '100%',
                backgroundColor: 'transparent',
                border: 'none',
                height: '49px'
            },
            '& .skill-container': {
                borderRadius: '23px',
                border: '1px solid #323232',
                maxWidth: '455px'
            },
            '& .h-63': {
                height: '63px !important'
            },
            '& .skill-list-container, & .active-skill-list-container': {
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                paddingLeft: '22px',
                paddingRight: '22px',
                marginBottom: '22px',
                overflow: 'auto',
                maxHeight: '100px',
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none'
            },
            ...customStyle.customStyle.tagsElm,
            '& .skill-suggestion': {
                fontSize: '12px',
                color: '#666',
                lineHeight: '14.52px',
                fontFamily: 'Inter, sans-serif',
                marginBottom: '16px',
                paddingTop: '16px',
                borderTop: '1px solid #323232',
                paddingLeft: '22px'
            },
            '& .active-skill-list-container': {
                paddingTop: '16px',
                marginBottom: '8px'
            },
            '& .progressbarContainer': {
                position: 'absolute',
                top: '49%',
                left: '28%',
                width: '44%',
                backgroundColor: '#111',
                zIndex: 22,
                height: '7px'
            },
            '& .progressbar': {
                position: 'absolute',
                height: '100%',
                maxWidth: '100%',
                background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
            }
        },
        '&.share-project-headerData': {
            display: 'flex',
            flexDirection: 'column',
            '& .banner-data': {
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '446px',
                marginTop: '76px',
            }
        },
        '&.share-project-popup': {
            overflow: "auto",
            scrollbarWidth: "none",
            MsOverflowStyle: "none",
            backgroundColor: "#1d1d1d",
            border: "none",
            width: "918px",
            borderRadius: "10px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column"
        },
        '&.share-project-popup .gap-elm': {
            margin: 0,
            padding: "5px 0"
        },
        '&.share-project-popup .discardBtn': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            height: "max-content",
            width: "max-content",
            color: "#fff2e3",
            padding: 0,
            backgroundColor: "transparent",
            border: "none"
        },
        '&.share-project-popup .saveProgressBtn': {
            width: "154px"
        },
        '&.share-project-popup input[type="range"]': {
            fontSize: "1.5rem",
            width: "12.5em",
            color: "#fff2e3",
            '--clip-edges': "0.125em",
            position: "relative",
            background: "#fff0",
            overflow: "hidden",
            '&::-webkit-slider-thumb': {
                height: '19px'
            },
            '&::-webkit-slider-runnable-track': {
                height: '6px',
                backgroundColor: '#626262',
                clipPath: 'inset(0 var(--clip-edges) 0 var(--clip-edges))',
            },
            '&:hover': {
                filter: 'brightness(180%)',
            },
            '&:active': {
                filter: 'brightness(80%)',
            }
        },
        '&.share-project-popup input[type="range"],&.share-project-popup input[type="range"]::-webkit-slider-runnable-track,&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            transition: 'all ease 100ms',
            height: 'var(--thumb-height)',
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-runnable-track,&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            position: "relative"
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            '--thumb-radius': 'calc((var(--thumb-height) * 0.5) - 1px)',
            '--clip-top': 'calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px)',
            '--clip-bottom': 'calc(var(--thumb-height) - var(--clip-top))',
            '--clip-further': 'calc(100% + 1px)',
            '--box-fill': 'calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor',
            width: 'var(--thumb-width, var(--thumb-height))',
            background: 'linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px)',
            backgroundColor: 'currentColor',
            boxShadow: 'var(--box-fill)',
            borderRadius: 'var(--thumb-width, var(--thumb-height))',
            filter: 'brightness(100%)',
            clipPath: 'polygon(100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%, var(--clip-further) var(--clip-further))',
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-runnable-track': {
            height: "4px",
            background: 'linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px)'
        },
        '&.share-project-popup input[type="range"],&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-thumb': {
            appearance: "none",
            transition: "all ease 100ms",
            height: "var(--thumb-height)"
        },
        '&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-thumb,&.share-project-popup input[type="range"]::-moz-range-progress': {
            background: "#fff0"
        },
        '&.share-project-popup input[type="range"]::-moz-range-thumb': {
            background: 'currentColor',
            border: 0,
            width: 'var(--thumb-width, var(--thumb-height))',
            borderRadius: 'var(--thumb-width, var(--thumb-height))',
            cursor: 'grab',
        },
        '&.share-project-popup input[type="range"]::-moz-range-track': {
            width: "100%",
            background: "var(--track-color)"
        },
        '&.share-project-popup input[type="range"]::-moz-range-progress': {
            appearance: "none",
            background: "currentColor",
            transitionDelay: "30ms"
        },
        '&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-progress': {
            height: 'calc(var(--track-height) + 1px)',
            borderRadius: 'var(--track-height)'
        },
        '&.share-project-popup input[type="range"]::-moz-range-thumb,&.share-project-popup input[type="range"]::-moz-range-progress': {
            filter: "brightness(100%)"
        },
        '&.share-project-popup .reactEasyCrop_Container': {
            height: "100%",
            width: "100%",
            left: "0 !important",
            top: "0 !important",
            bottom: 0
        },
        '&.share-project-popup .controls': {
            display: "flex",
            alignItems: "center",
            gap: "19px"
        },
        '&.share-project-popup .controls .imgIconFirst': {
            marginLeft: "10px",
            width: "25px",
            height: "25px"
        },
        '&.share-project-popup .controls .imgIconLast': {
            marginLeft: "10px",
            width: "37px",
            height: "37px"
        },
        '&.share-project-popup .slider': {
            padding: "22px 0px"
        },
        '&.share-project-popup .reactEasyCrop_CropArea': {
            border: "1px solid #fff2e3 !important"
        },
        '&.share-project-popup .reactEasyCrop_CropArea::before,&.share-project-popup .reactEasyCrop_CropArea::after': {
            border: "none !important"
        },
        '&.share-project-popup .create-project': {
            fontFamily: "Inter-Medium,sans-serif !important"
        },
        '&.share-project-popup .create-project:active':  {
            opacity: 0.5
        },
        '&.share-project-popup .pointer-cursor': {
            cursor: "pointer"
        },
        '&.share-project-popup .bold-elm': {
            fontFamily: "Inter-SemiBold,sans-serif !important"
        },
        '&.share-project-popup .space-between-elm': {
            display: "flex",
            justifyContent: "space-between !important",
            width: "100%"
        },
        '&.share-project-popup .right-section-elm': {
            gap: "34px",
            marginRight: "35px"
        },
        '&.share-project-popup .left-elm-container': {
            marginLeft: "35px"
        },
        '&.share-project-popup .relative-position': {
            position: "relative",
            borderTop: "0.54px solid #525252",
            borderBottom: "0.54px solid #525252",
            paddingLeft: "35px",
            paddingRight: "35px",
            width: "84%"
        },
        '&.share-project-popup .crop-border-container': {
            position: "absolute",
            border: "1px solid #8C8C8C",
            bottom: "9.3%",
            left: "12.3%",
            borderRadius: "10px",
            zIndex: 99
        },
        '&.share-project-popup .flex-container': {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        '&.share-project-popup .h-94': {
            height: "94px"
        },
        '&.share-project-container .fs-16': {
            fontSize: "16px !important",
            left: "16.36px !important"
        },
        '&.share-project-popup .close-icon': {
            width: "12px",
            height: "14.24px"
        },
        '&.share-project-container .text-elm,&.share-project-popup .text-elm': {
            fontFamily: "Inter,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#fff2e3"
        },
        '&.share-project-container .skill-list-container::-webkit-scrollbar,&.share-project-container .active-skill-list-container::-webkit-scrollbar': {
            width: 0,
            height: 0
        },
        '&.share-project-container .d-none': {
            display: "none"
        },
        '&.share-project-headerData .fixed-top': {
            position: "fixed",
            width: "100%",
            zIndex: 999
        },
        '&.story-upload-btn.closebtn': {
            position: "absolute",
            top: "-6px",
            right: "-2px",
            padding: 0,
            border: "none",
            cursor: "pointer",
            background: "transparent"
        },
        '&.share-project-popup .closebtn-elm': {
            width: "12px",
            height: "14.24px"
        },
        '&.share-project-popup .crop-container': {
            width: "100%",
            height: "536px"
        },
        '&.share-project-popup .cornerBox': {
            width: "38.8px",
            height: "42.33px",
            border: "10px solid #fff2e3",
            position: "absolute",
            zIndex: 2
        },
        '&.share-project-popup .cornerBox1': {
            left: '11.8%',
            borderRightStyle: 'none',
            borderBottomStyle: 'none',
            top: '8.3%',
            borderRadius: '10px 0px 0px 0px'
        },
        '&.share-project-popup .cornerBox2': {
            right: '11.8%',
            borderBottomStyle: 'none',
            borderLeftStyle: 'none',
            top: '8.3%',
            borderRadius: '0px 10px 0px 0px',
        },
        '&.share-project-popup .cornerBox3': {
            right: '11.8%',
            bottom: '8.5%',
            borderLeftStyle: 'none',
            borderTopStyle: 'none',
            borderRadius: '0px 0px 10px 0px',
        },
        '&.share-project-popup .cornerBox4': {
            left: '11.8%',
            bottom: '8.5%',
            borderRightStyle: 'none',
            borderTopStyle: 'none',
            borderRadius: '0px 0px 0px 10px',
        },
        '&.create-collection-modal': {
            display: "flex",
            flexDirection: "column",
            width: "572px",
            padding: "56px 0px",
            gap: "40px",
            borderRadius: "12px",
            backgroundColor: "#222222",
            alignItems: "center",
            justifyContent: "center"
        },
        '&.collection-form .form-title': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#fff3e3"
        },
        '&.collection-form .input-elm': {
            fontFamily: "Inter,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#848181",
            width: "423px",
            height: "60px",
            borderRadius: "9px",
            paddingLeft: "16px",
            paddingRight: "16px",
            border: "none",
            backgroundColor: "#171717"
        },
        '&.collection-form .input-group': {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "32px"
        },
        '&.collection-form .btn-container': {
            display: "flex",
            gap: "24px"
        },
        '&.collection-form .cancel-btn': {
            width: "115px"
        },
        '&.collection-form .submit-btn': {
            width: "114px"
        },
        [theme.breakpoints.between(1280, 1320)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "285px !important"
            }
        },
        [theme.breakpoints.between(1321, 1360)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "297px !important"
            }
        },
        [theme.breakpoints.between(1361, 1390)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "300px !important"
            }
        },
        [theme.breakpoints.between(1391, 1420)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "310px !important"
            }
        },
        [theme.breakpoints.between(1421, 1460)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "320px !important"
            }
        },
        [theme.breakpoints.between(1461, 1490)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "330px !important"
            }
        },
        [theme.breakpoints.between(1491, 1550)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "340px !important"
            }
        },
        [theme.breakpoints.between(1551, 1600)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "350px !important"
            }
        },
        [theme.breakpoints.between(1601, 1700)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "370px !important"
            }
        },
        [theme.breakpoints.between(1701, 1800)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "390px !important"
            }
        },
        [theme.breakpoints.between(1801, 1880)]: {
            '&.share-project-headerData .banner-data': {
                minHeight: "400px !important"
            }
        }
    }
}))(Box);

const theme = createTheme({
    components: {
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingLeft: "0",
                    paddingRight: "0",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    MsOverflowStyle: "none",
                    border: "none",
                    borderRadius: "10px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0
                    }
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    maxWidth: "918px !important",
                    backgroundColor: "transparent !important",
                    boxShadow: "none",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    MsOverflowStyle: "none",
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0
                    }
                },
            },
        }
    },
});

class ShareProject extends ShareProjectController {
  constructor(props: Props) {
    super(props);
  }
  endAdornment = () =>{
    return(
        this.state.searchText.length > 0 && (
            <InputAdornment position="end">
                <IconButton
                    id="clear-text"
                    style={{
                        color: "white",
                        fontFamily: "Inter, sans-serif",
                    }}
                    size="small"
                    onClick={this.handleCancelForShare}
                >
                    x
                </IconButton>
            </InputAdornment>)
    )
  }
  render() {
    return (
        <ThemeProvider theme={theme}>
        <CustomBox data-test-id="parent-container" style={{overflowY:'scroll',scrollbarWidth:'none'}} onClick={() => this.hidemenu()}>
        <CustomBox className="share-project-headerData">
            <CustomBox className="fixed-top"><NavigationMenu {...this.props} activeTab="share project" /></CustomBox>
            <CustomBox className="banner-data" style={{backgroundImage: `url(${shareProject})`}}></CustomBox>
        </CustomBox>
      <CustomBox className="share-project-container">
        <CustomBox className="header-container">
            <CustomBox className="left-elm">
                <img data-test-id="back-btn" onClick={() => this.redirectTo('LandingPage')} className="back-icon" src={`${leftArrowIcon}`} alt="back icon" />
                <Typography className="heading-text">Share Project</Typography>
            </CustomBox>
            <CustomBox className="right-section">
                <CustomBox 
                    className="discard-container" 
                    data-test-id="discard-container"
                    style={{ display: this.handleShowDiscard() ? "flex": "none"}}
                    onClick={() => this.discardChanges()}
                >
                    <img src={`${deleteIcon}`} alt="delete icon" />
                    <Typography className="discard-text">Discard</Typography>
                </CustomBox>
                <CustomButton
                    disabled={!this.enableCreateProject()}
                    className={`button-elm create-project ${this.enableCreateProject() ? 'secondary-btn': 'disabled-btn'}`}
                    onClick={() => this.createProject()}
                >Share Project</CustomButton>
            </CustomBox>
        </CustomBox>
        <CustomBox className="form-container">
        <CustomBox style={{display: "flex", flexDirection: "column", gap: "36px", width: "100%"}}>
            {this.state.profileImage.map((image, index) => <CustomBox key={`profile${index}`} style={{ position: 'relative'}}>
                <CustomBox className="profile-image-container" style={{opacity: this.showProgressbar(index) ? "0.3": "1", display: this.showProgressbar(index) ? "flex": "block", backgroundImage: `url(${this.handleURL(image)})`, paddingTop: '13px'}}>
                    <CustomBox className="delete-profile"><img data-test-id="delete-profile-image" onClick={() => this.deleteProfile(index)} src={`${deleteIcon}`} alt="delete profile"/> </CustomBox>
                </CustomBox>
                {this.showProgressbar(index) ? <CustomBox className="progressbarContainer" style={{opacity: 1}}>
                    <CustomBox className="progressbar" style={{ width: this.state.loadedImage + '%', opacity: 1}}></CustomBox>
                </CustomBox>: null}
            </CustomBox>)}
            <CustomBox 
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                className="profile-upload-container" style={{minHeight: this.state.profileImage.length ? "600px": "1583px", display: (this.state.profileImage.length < 25) ? "flex": "none", justifyContent: this.state.profileImage.length ? "center": "flex-start"}}>
                <CustomBox className="drag-text" style={{ marginTop: this.state.profileImage.length ? "": "318px"}}>
                    <img src={`${uploadIcon}`} alt="upload profile"/>
                    Drop your work here
                </CustomBox>
                or
                <CustomButton key={`profileImage${this.state.profileImage.length}`} className="button-elm default-btn profile-label" onClick={this.handleUploadFileClick} data-test-id="upload-file-button">
                    <InputLabel style={{color: "#fffee3", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px"}} onClick={e => e.stopPropagation()} data-test-id="upload-file-label">
                        <Input 
                            id="profile-image" 
                            data-test-id="upload-file-input"
                            type="file" 
                            ref={this.refElmFile}
                            hidden 
                            inputProps={{
                                accept: "image/*"
                            }}
                            onChange={this.handleProfileImage}
                            style={{display: "none"}}
                        />
                        Upload files
                    </InputLabel>
                </CustomButton>
            </CustomBox>
        </CustomBox>
        <CustomBox className="right-container" style={{display: "flex", flexDirection: "column", rowGap: "48px", padding: "48px 38px", borderRadius: "12px", backgroundColor: "#171717", maxWidth: "533px", height: "max-content", width: "max-content"}}>
            <CustomBox className="title-container">
                <Typography className="title-text">Project Title*</Typography>
                <CustomTextField 
                    className="project-title"
                    type="text"
                    placeholder="Type here"
                    value={this.state.title}
                    inputProps={{
                        maxLength: 50
                    }}
                    onChange={(event) => this.handleTitleChange(event.target.value)}
                />
            </CustomBox>
            <CustomBox className="description-container">
                <Typography className="title-text gap-elm">Description</Typography>
                <CustomTextField 
                    className="description-title"
                    placeholder="Type here"
                    value={this.state.description}
                    ref={this.textareaRef}
                    inputProps={{
                        maxLength: 501
                    }}
                    //maxRows={4}
                    multiline={true}
                    onChange={(event) => this.handleDescriptionChange(event.target.value)}
                />
                <Typography className="descriptionError">{this.state.descriptionError}</Typography>
            </CustomBox>
            <CustomBox style={{display: "flex", columnGap: "40px", }}>
                <CustomBox className="flex-elm">
                    <Typography className="title-text gap-elm">Collection*</Typography>
                    <CustomBox className="select-container">
                        <CustomButton
                            data-test-id="collection-toggle"
                            className={`select-btn ${this.state.isCollectionOpen ? "menu-open": ""}`}
                            onClick={(event) => this.toggleCollectionMenu(event)}
                            disableRipple={true}
                        >
                            {(this.state.isCollectionOpen || !this.state.collection.name) ? "Select": this.state.collection.name}
                            <img className={`${this.state.isCollectionOpen ? 'moveup': ""}`} src={`${dropDownIcon}`} alt="dropdown icon" />
                        </CustomButton>
                        <CustomBox className="option-container-collection" style={{display: this.state.isCollectionOpen ? "block": "none"}}>
                            {this.state.collectionList.map((list, index) => <CustomBox 
                                className={`menu-item ${this.state.collection.name === list.name ? "active": ""}`} 
                                key={`collection${index}`}
                                data-test-id="collection-menu" 
                                onClick={() => this.handleCollection(list)}
                            >{list.name}</CustomBox>)}
                            <CustomBox
                                data-test-id="create-collection"
                                className={`menu-item ${!this.state.collection.name ? "active": ""}`}
                                onClick={() => this.openCreateCollection()}
                            >Create new collection</CustomBox>
                        </CustomBox>
                    </CustomBox>
                </CustomBox>
                <CustomBox className="flex-elm">
                    <Typography className="title-text gap-elm">Select Category *</Typography>
                    <CustomBox className="select-container">
                        <CustomButton
                            data-test-id="toggle-menu"
                            className={`select-btn ${this.state.isCategoryOpen ? "menu-open": ""}`}
                            onClick={(event) => this.toggleCategoryMenu(event)}
                            disableRipple={true}
                        >
                            {(this.state.isCategoryOpen || !this.state.category.name) ? "Select": this.state.category.name}
                            <img className={`${this.state.isCategoryOpen ? 'moveup': ""}`} src={`${dropDownIcon}`} alt="dropdown icon" />
                        </CustomButton>
                        <CustomBox className="option-container-category" style={{display: this.state.isCategoryOpen ? "block": "none"}}>
                            {this.state.categoryList.map((list, index) => <CustomBox 
                                className={`menu-item ${this.state.category.name === list.name ? "active": ""}`}
                                key={`category${index}`} 
                                data-test-id="category-menu"
                                onClick={() => this.handleCategory(list)}
                            >{list.name}</CustomBox>)}
                            {!this.state.categoryList.length && <CustomBox 
                                className="menu-item"
                            >No record found.</CustomBox>}
                        </CustomBox>
                    </CustomBox>
                </CustomBox>
            </CustomBox>
            <CustomBox className="flex-elm">
                <Typography className="title-text gap-elm">Add Skill tags*</Typography>
                <CustomBox className="skill-container">
                    {this.state.selectedSkillTags.length ? <CustomBox className="active-skill-list-container">
                    {this.state.selectedSkillTags.map((list, index) => <CustomBox 
                        className="tags tags-active" 
                        data-test-id="selectedSkillTags"
                        key={`selectedSkillTags${index}`}
                        onClick={() => this.handleRemoveSkillTags(list)}
                    >
                        <img src={`${closeIcon}`} alt="remove skill" />{list.name}</CustomBox>)}
                    </CustomBox>: null}
                    <CustomBox className={`search-container`}>
                        <img src={`${searchIcon}`} alt="search skill tags" />
                        <CustomTextField 
                            type="text"
                            placeholder="Search"
                            data-test-id="skill-search-end"
                            className={this.handleClassess()}
                            disabled={!this.state.skillList.length}
                            value={this.state.searchText}
                            onChange={(event) => this.handleChange(event.target.value)}
                            endAdornment={this.endAdornment()}
                        />
                    </CustomBox>
                    {((this.state.searchText.trim() && this.state.searchSkillList.length) || (!this.state.searchText.trim() && this.state.skillList.length)) ? <><CustomBox className="skill-Suggestion">Suggestions</CustomBox><CustomBox className="skill-list-container">
                        {(this.state.searchSkillList.length ? this.state.searchSkillList: this.state.skillList).map((list, index) => <CustomBox 
                            className="tags"
                            data-test-id="skillList"
                            key={`skillTags${index}`} 
                            onClick={() => this.handleAddSkillTags(list)}
                        >{list.name}</CustomBox>)}</CustomBox></>
                        :<><Typography className="skill-Suggestion">Suggestions</Typography><CustomBox className="skill-list-container text-elm fs-16">No result found</CustomBox></>}
                </CustomBox>
            </CustomBox>
            <CustomBox>
                <CustomBox className="cover-container title-text">
                    Upload Cover* <img className={`${this.showEditCover()}`} data-test-id="edit-cover" onClick={() => this.handleEditCover()} src={`${editIcon}`} style={{cursor:"pointer"}} alt="edit cover" />
                </CustomBox>
                <CustomBox 
                    className={`cover-image-container ${this.state.croppedImage ? 'no-border': ''}`} 
                    data-test-id="cover-image-container"
                    onDragOver={this.handleDragOver}
                    onDrop={(event) => this.handleDrop(event, "coverImage")}
                    style={{ backgroundImage: this.state.croppedImage ? `url(${this.state.croppedImage})`: "none"}}
                >
                    <CustomBox style={{alignItems: "center", display: this.state.croppedImage ? "none": "flex", flexDirection: "column"}}>
                        <CustomButton key={`coverImage${this.state.coverImage.length}`} className="button-elm default-btn cover-label" onClick={this.handleUploadImageClick} data-test-id="upload-image-button">
                            <InputLabel style={{color: "#fffee3", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px"}}onClick={e => e.stopPropagation()} data-test-id="upload-image-label">
                                <Input 
                                    id="cover-image" 
                                    type="file" 
                                    hidden 
                                    inputProps={{
                                        accept: "image/*"
                                    }}
                                    ref={this.refElm}
                                    onChange={this.handleCoverImage}
                                    style={{ display: "none" }}
                                />
                                Upload Image
                            </InputLabel>
                        </CustomButton>
                        <CustomBox className="hint-text">
                            Minimum size of “808x632px”
                        </CustomBox>
                    </CustomBox>
                </CustomBox>
            </CustomBox>
        </CustomBox>
        </CustomBox>
        {this.state.showCropper && <Dialog
            open={this.state.showCropper}
            onClose={() => this.closePopup()}
            fullWidth={true}
            maxWidth="lg"
            classes={{ paper: "share-project-popup-wrapper" }}
        >
            <DialogTitle>
                <CustomBox
                    className="story-upload-btn closebtn"
                    data-test-id="close-btn-share-project"
                    onClick={() => this.closePopup()}
                >
                    <img
                        src={closeIcon}
                        alt="close-icon"
                        className="closebtn-elm"
                    />
                </CustomBox>
            </DialogTitle>
            <DialogContent 
                style={{ 
                    paddingTop: 0, 
                    paddingBottom: 0,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }}
            >
                <DialogContentText>
                    <CustomBox
                        className="share-project-popup"
                    >
                        <CustomBox
                            className="flex-container h-94 space-between-elm"
                        >
                            <Typography className="text-elm bold-elm left-elm-container">
                                Upload cover image
                            </Typography>
                            <CustomBox className="right-section-elm flex-container">
                                <CustomButton
                                    className="discardBtn"
                                    data-test-id="discard-btn"
                                    onClick={this.handleDiscardCroppedImage}
                                >
                                    Cancel
                                </CustomButton>
                                <CustomButton
                                    className="saveProgressBtn button-elm default-btn"
                                    data-test-id="save-Progress-btn"
                                    onClick={this.handleCroppedImage}
                                >
                                    Upload cover
                                </CustomButton>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className="relative-position">
                            <CustomBox className="crop-container">
                                <CustomBox>
                                </CustomBox>
                                <Cropper
                                    image={URL.createObjectURL(this.state.coverImage[0])}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={1.45}
                                    onCropChange={this.handleCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.handleZoomChange}
                                />
                            </CustomBox>
                            <CustomBox className="crop-border-container"></CustomBox>
                        </CustomBox>
                        <CustomBox className="controller-container flex-container h-94">
                            <CustomBox className="controls h-94">
                                <img
                                    src={imgIcon}
                                    alt="img Icon small"
                                    className="imgIconFirst"
                                />
                                <Slider data-test-id="zoom-input" min={1}
                  max={3}
                  step={0.1} 
                  value={this.state.zoom}
                   onChange={this.handleZoomChangeFromSlider} aria-labelledby="continuous-slider" />
                                <img
                                    src={imgIcon}
                                    alt="img Icon large"
                                    className="imgIconlast"
                                />
                            </CustomBox>
                        </CustomBox>
                    </CustomBox>
                </DialogContentText>
            </DialogContent>
        </Dialog>}
        {this.state.openCreateCollection && <Dialog
            open={this.state.openCreateCollection}
            onClose={() => this.closeCreateCollection()}
            classes={{ paper: "create-collection-dialog" }}
        >
            <DialogContent 
                style={{
                    marginTop: "20px", 
                    marginBottom: "24px", 
                    paddingTop: 0, 
                    paddingBottom: 0,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }}
            >
                <DialogContentText>
                    <CustomBox className="create-collection-modal collection-form">
                        <CustomBox className="input-group">
                            <CustomBox className="form-title">Create New Collection</CustomBox>
                            <CustomTextField 
                                type="text"
                                className="input-elm"
                                data-test-id="input-elm"
                                placeholder="Collection Name"
                                value={this.state.newCollection}
                                onChange={(event) => this.handleNewCollection(event.target.value)}
                            />
                        </CustomBox>
                        <CustomBox className="btn-container">
                            <CustomButton
                                className="button-elm default-btn cancel-btn"
                                data-test-id="cancel-btn"
                                onClick={() => this.closeCreateCollection()}
                            >Cancel</CustomButton>
                            <CustomButton
                                disabled={!this.state.newCollection}
                                data-test-id="submit-btn"
                                className={`button-elm submit-btn ${(this.state.newCollection) ? 'secondary-btn': 'disabled-btn'}`}
                                onClick={() => this.handleCreateCollection()}
                            >Create</CustomButton>
                        </CustomBox>
                    </CustomBox>
                </DialogContentText>
            </DialogContent>
        </Dialog>}
        <ToastMSG data-test-id="toastMessage" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action}/>
      </CustomBox>
      </CustomBox>
      </ThemeProvider>
    );
  }
}

export default ShareProject;

// Customizable Area End



    
