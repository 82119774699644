// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.updateApiMethodType='PUT';
exports.deleteApiMethodType = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PortfolioManagement";
exports.labelBodyText = "PortfolioManagement Body";
exports.getPortfolioList = 'bx_block_portfolio_management/portfolios/get_projects_based_collection?'
exports.getcollectionlist ="bx_block_portfolio_management/portfolios/get_collections/";
exports.DeleteCollectionList = "bx_block_portfolio_management/portfolios/delete_collection/"
exports.UpdateCollectionName='bx_block_portfolio_management/portfolios/update_collection_name/'
exports.btnExampleTitle = "CLICK ME";
exports.baseURL = require('../../../framework/src/config');
exports.InspirationUserDetails='bx_block_dashboard/inspirations?account_id=';
exports.InspirationUserProjects='bx_block_dashboard/inspirations/';
exports.DeleteInspirationProfile='bx_block_dashboard/inspirations/';
exports.updateInspirationProfilename='bx_block_dashboard/inspirations/';
exports.DeleteInspirationSingleProject='bx_block_dashboard/inspirations/delete_project_from_inspiration?';
exports.InspirationFollowingBoards='bx_block_dashboard/inspirations/user_followings_inspirations?';
exports.FollowingboardUnFollow='bx_block_dashboard/follow_inspirations/';
exports.getCategoriesList="bx_block_projectportfolio/get_categories";
exports.getSkillEndPoint='bx_block_projectportfolio/get_skills';
exports.getAllSkillsbyid="bx_block_projectportfolio/skills_by_category?category_id="
exports.AddProfessionalWorkExp='bx_block_projectportfolio/professional_experiences';
exports.getallListsofProfessionalWxp='bx_block_projectportfolio/professional_experiences?account_id='
exports.deletesingleProfessionalExp = 'bx_block_projectportfolio/professional_experiences/';
exports.searchSkillEndPoint = "bx_block_projectportfolio/search_skill";
exports.followApiEndPoint ='bx_block_dashboard/follow_inspirations'
// Customizable Area End
