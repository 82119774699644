import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider.web";
import React, { createRef, RefObject } from "react";
interface IDesignAttribute {
  id: number;
  country: string;
  address: null;
  city: null;
  postal_code: string;
  account_id: number;
  photo: null;
  background_color: null;
  website_link: null;
  about: null;
  expertise_category: [];
  skills: [];
  languages: [];
  min_per_hour: null;
  max_per_hour: null;
  dribble: null;
  instagram: null;
  linkedin: null;
  member_since: string;
  followers_count: number;
  following_count: number;
  work_experience: never[];
  average_rate: null;
  user_role: {
    role_id: number;
    role_name: string;
  };
  profile_image_id: null;
  profile_cover_image: null;
  name: string;
  ratings: number;
  opportunities_count: number;
  views: number;
  likes: number;
  user_name: string;
  average_rate_per_hour: number;
  company_details: null;
}
interface IDesignerList {
  id: string;
  type: string;
  attributes: IDesignAttribute
}
interface RowInt {
  id: string | number, 
  name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  openMulitCreateFeild: boolean;
  openMulitSkills: boolean;
  openSingle: boolean;
  selectedCreativeFeilds: RowInt[];
  selectedSkills: RowInt[];
  selectedElm: string | number,
  allCategory: RowInt[];
  allSkillsList: RowInt[];
  dezinerToken: string;
  rateMin:number;
  rateMax:number;
  isSliderChanged: boolean;
  ratingObject: Record<string,string>;
  selectedRatings: number[];
  userRole: string;
  tabsList: {
    tabName: string;
    tabId: string;
  }[],
  activeTab: string;
  totalDesignersProfileCount: number;
  currentDesignerPage: number;
  totalDesingersPages: number;
  desingerProfileList:IDesignerList[];
  isOpen: boolean;
  openedData: null | IDesignAttribute ;
  isIdSelected: number;
  ratingOpen: boolean;
  rateOpen: boolean;
  search: string;
  initialLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  getCreativeFeildsId: string = "";
  getSkillsId: string = "";
  designersSearchResultsId: string = "";
  private perPage: number = 12;
  designerCardRef:  RefObject<HTMLDivElement> = createRef();
  onClickAwayRef: RefObject<HTMLDivElement> = createRef();
  onClickAwayRateRef: RefObject<HTMLDivElement> = createRef();
  private designersProfileObserver?: IntersectionObserver;
  debounceTimerId: null | NodeJS.Timeout = null;
  debounceTime: number = 350;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      tabsList: configJSON.tabsData,
      openMulitCreateFeild: false,
      openMulitSkills: false,
      openSingle: false,
      selectedCreativeFeilds: [],
      selectedSkills: [],
      selectedElm: "",
      allCategory: [],
      allSkillsList: [],
      dezinerToken: "",
      rateMin:1000,
      rateMax:4000,
      isSliderChanged: false,
      ratingObject: {},
      selectedRatings: [],
      userRole: "",
      totalDesignersProfileCount: 0,
      currentDesignerPage: 1,
      totalDesingersPages: 1,
      desingerProfileList: [],
      isOpen: false,
      openedData: null,
      isIdSelected: 0,
      search: "",
      ratingOpen: false,
      rateOpen: false,
      initialLoading: true,
      activeTab: "designer"
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getTokenFromLocalStorage();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.advancedsearchApiCallId) {
            this.showAlert("Alert", "API Error", "");
          }
        }
      }
      this.handleMessages(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getActiveTabBorder = (tabName: string) => {
    return this.state.activeTab === tabName ? "tabOuterCon gradientCon" : "tabOuterCon"
  }

  getActiveTabBackground = (tabName: string) => {
    return this.state.activeTab === tabName ? "activeTab tab" : "tab"
  }

  getActiveTabFont = (tabName: string) => {
    return this.state.activeTab === tabName ? "activeFont" : "inActiveFont"
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.dezinerToken,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const apiErrorMessageData = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    return ({ requestCallId, response, apiErrorMessageData })
  }

  handleMessages = (message: Message) => {
    const { requestCallId } = this.getResponseFromMessage(message);
    if (requestCallId === this.getCreativeFeildsId) {
      this.handleSkillsData(message);
    }
    if(requestCallId === this.designersSearchResultsId){
      this.handleDesingerSearchResults(message);
    }

    if(requestCallId === this.getSkillsId){
      this.handleSkillsList(message);
    }
  }

  toggleMultiCreativeFeild = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openMulitCreateFeild: !this.state.openMulitCreateFeild,
      openSingle: false,
      openMulitSkills: false,
    });
    event.stopPropagation();
  }

  toggleMultiSkills = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openMulitSkills: !this.state.openMulitSkills,
      openMulitCreateFeild: false,
      openSingle: false,
    });
    event.stopPropagation();
  }

  handleClickOutside = (event: MouseEvent) => {
    if (this.onClickAwayRef.current && !this.onClickAwayRef.current.contains(event.target as Node)) {
      this.setState({
        ratingOpen: false,
        rateOpen: false,
      });
    }
  }

  handleClickOutSideOfMultiSelect = () => {
    this.setState({ 
      openMulitCreateFeild: false,
      openMulitSkills: false,
      openSingle: false,
    })
  }

  getTokenFromLocalStorage = async () => {
    document.addEventListener('mousedown', this.handleClickOutside);
    let tokenDetail = await getStorageData("userInfo");
    let advancedSearch = await getStorageData("advancedSearch");
    const tokenParseData = JSON.parse(tokenDetail);
    const rolesData = JSON.parse(advancedSearch);
    let token: string = "";
    let userRole: string = "";
    let activeTab: string = "designer";
    let search: string = "";
    rolesData.role && (activeTab = rolesData.role);
    rolesData.search && (search = rolesData.search);
    if(tokenParseData && tokenParseData.data && tokenParseData.data.attributes) {
      userRole = tokenParseData.data.attributes.role_name;
    }
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);

      this.setState({ 
        dezinerToken: token,
         userRole, 
         activeTab,
         selectedElm: userRole === "designer" ? configJSON.designerSortData[0]: configJSON.clientSortData[0],
         search}, () => {
        this.getCreativeFeilds();
        this.getSkillsList();
        this.getDesingerSearchResults();
      })
    } 
  }

  getCreativeFeilds = () => {
    const message = this.createMessage(configJSON.getCategories, configJSON.httpGetMethod)
    this.getCreativeFeildsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  } 

  getSkillsList = () => {
    const message = this.createMessage(configJSON.skillsListEndPoint, configJSON.httpGetMethod)
    this.getSkillsId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  toQueryParams = (object: Record<string , string | number | string[] | number[]>) => {
    const queryParams = Object.entries(object).
    map(([keyName,value]:[string,string | number | string[] | number[]])=>{
      if(Array.isArray(value)){
      return `${encodeURIComponent(keyName)}[]=${encodeURIComponent(value.join(","))}`
      }
      return `${encodeURIComponent(keyName)}=${encodeURIComponent(value)}`
    })
    return queryParams.join("&");
  }

  getDesingerSearchResults = () => {
    const queryObject = Object.create({});
    queryObject["per_page"] = this.perPage;
    queryObject["page"] = this.state.currentDesignerPage;
    queryObject["search"] = this.state.search;
    (this.state.rateMin !== null && this.state.isSliderChanged) && (queryObject["rate[min]"] = this.state.rateMin);
    (this.state.rateMax !== null && this.state.isSliderChanged) && (queryObject["rate[max]"] = this.state.rateMax);
    (this.state.selectedCreativeFeilds.length > 0) && (queryObject["categories"] = this.state.selectedCreativeFeilds.map(category => category.id));
    (this.state.selectedSkills.length > 0) && (queryObject["skills"] = this.state.selectedSkills.map(skill => skill.id));
    (this.state.selectedRatings.length > 0) && (queryObject["rating"] = this.state.selectedRatings);
    (this.state.selectedElm !== "") && (queryObject["sort"] = this.state.selectedElm.toString().replace("Service Rate: ","").replace(/ /g,"_").toLowerCase())

    const queryString = this.toQueryParams(queryObject);
    const endPoint = `${configJSON.designerEndPoint}?${queryString}`;
    const message = this.createMessage(endPoint, configJSON.httpGetMethod);
    this.designersSearchResultsId = message.messageId;
    this.storeInLocalStorage();
    runEngine.sendMessage(message.id,message);
  }

  handleSkillsData = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (Array.isArray(responseJson.data)) {
        this.setState({ allCategory: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string
        }) => ({id: item.id, name: item.name }))]})
      }
    }
  }

  handleSkillsList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors) {
      if (Array.isArray(responseJson.data)) {
        this.setState({ allSkillsList: [...responseJson.data.map((item: {
          id: string | number,
          name: string,
          created_at: string,
          updated_at: string
        }) => ({id: item.id, name: item.name }))]})
      }
    }
  }

  handleDesingerSearchResults = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!!responseJson.designers_profiles && Array.isArray(responseJson.designers_profiles.data)) {
      this.setState(prev => (
        { 
          desingerProfileList: prev.currentDesignerPage === 1 ? 
          responseJson.designers_profiles.data : 
          [...prev.desingerProfileList,...responseJson.designers_profiles.data],
          initialLoading: false,
         }
      )
      );
    }

    if (!!responseJson.rating_data) {
      this.setState({ ratingObject: responseJson.rating_data })
    }

    if (!!responseJson.profile_count) {
      this.setState({ totalDesignersProfileCount: responseJson.profile_count },() => {
        this.setState(prev=>({totalDesingersPages: Math.ceil(prev.totalDesignersProfileCount/this.perPage)}))
      })
    }

    if(!!responseJson.errors){
      this.setState(prev => ({
        desingerProfileList: [],
        ratingObject: !prev.ratingOpen  ? {} : prev.ratingObject,
        totalDesingersPages: 1,
        currentDesignerPage: 1,
        initialLoading: false,
      }))
    }

  }

  handleRatingFilter = (count:number) => {
    this.setState(prev => {
      return({
        selectedRatings: prev.selectedRatings.includes(count) ?  
        prev.selectedRatings.filter(rating => rating !== count) :
         [...prev.selectedRatings,count].sort((valueOne,valueTwo) => valueOne - valueTwo),
        currentDesignerPage: 1,
        initialLoading: true,
        desingerProfileList: [],
      })
    },this.getDesingerSearchResults)
  }

  isRatingSelected = (count:number) => {
    return this.state.selectedRatings.includes(count);
  }

  handleChangeCreativeFeilds = (newData: { id: string | number, name: string }[]) => {
    this.setState({
      selectedCreativeFeilds: newData,
      currentDesignerPage: 1,
      desingerProfileList: [],
      initialLoading: true,
    }, this.getDesingerSearchResults);

  }

  handleChangeSkills = (newData: {id: string | number, name: string}[]) => {
    this.setState({
      selectedSkills: newData,
      currentDesignerPage: 1,
      desingerProfileList: [],
      initialLoading: true,
    },this.getDesingerSearchResults);
  }

  handleRatingMenuOpen = () => { 
    this.setState({ 
      openSingle: false, 
      openMulitCreateFeild: false, 
      openMulitSkills: false,
      ratingOpen: true,
    })
  }

  handleRateMenuOpen = () => { 
    this.setState({ 
      openSingle: false,
      openMulitCreateFeild: false, 
      openMulitSkills: false,
      rateOpen: true,
     })
  }


  handleRangeSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    const newValue = value as number[]
    this.setState({
      rateMin: newValue[0],
      rateMax: newValue[1],
      isSliderChanged: true,
      currentDesignerPage: 1,
      desingerProfileList: [],
      initialLoading: true,
    }, () => {
      this.getDesingerSearchResults();
    })
  }

  toggleSingle = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ 
      openSingle: !this.state.openSingle, 
      openMulitCreateFeild: false,
      openMulitSkills: false, 
    });
    event.stopPropagation();
  }

  handleNavigateToLandingPage = () => {
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }

  handleSelectChange = (newData: string | number) => {
    this.setState({
      selectedElm: newData,
      openSingle: false,
      currentDesignerPage: 1,
      initialLoading: true,
      desingerProfileList: [],
    },this.getDesingerSearchResults)
  }

  handleNavigations = async (item: { account_id: number, user_role: { role_name: string } }) => {
    let localdata = {
      account_id: item.account_id,
      user_role: item.user_role.role_name
    }

    await setStorageData("PublicId", JSON.stringify(localdata));
    const message: Message =
      new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "PublicUserProfile");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(message);
  }

  handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, idIndex: number) => {
    event.stopPropagation()
    if (this.state.isOpen) {
      return
    }
    this.setState({
      isIdSelected: idIndex,
      openedData: this.state.desingerProfileList[idIndex].attributes
    }, () => {
      this.setState({ isOpen: true })
    })
  }


  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter" && !event.shiftKey){
      this.handleSearchButtonClick();
    }
  }

  handleSearchButtonClick = () => {
    if(this.state.search.length > 2) {
      this.setState({
        currentDesignerPage: 1,
        desingerProfileList: [],
        initialLoading: true,
      });
      this.getDesingerSearchResults();
    }
  }

  handleTabChange = (tabName: string) => {
    this.setState({ activeTab: tabName }, () => {
      this.storeInLocalStorage();
    })
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      search: event.target.value,
    },() => {
      if(this.state.search.length === 0) {
        this.handleCloseButton();
      }
    })
  }

  handleCloseButton = () => {
    this.setState({
      search: "",
      currentDesignerPage: 1,
    },this.getDesingerSearchResults)
  }

  handleDesingersScrool = () => {
    if (!!this.designersProfileObserver) {
      this.designersProfileObserver.disconnect();
    }

    const userListCallBack = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (this.state.desingerProfileList.length < this.state.totalDesignersProfileCount) {
          this.setState(prev => ({ currentDesignerPage: prev.currentDesignerPage + 1, initialLoading: true }), () => {
            this.getDesingerSearchResults();
          })
        }
      }
    }
    this.designersProfileObserver = new IntersectionObserver(userListCallBack, { threshold: 0 });
    if (this.designerCardRef.current) {
      this.designersProfileObserver.observe(this.designerCardRef.current)
    }
  }

  storeInLocalStorage = async () => {
    await storage.set("advancedSearch", JSON.stringify({ role: this.state.activeTab, search: this.state.search }));
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(this.state.desingerProfileList.length > 0 && 
      (prevState.desingerProfileList.length !== this.state.desingerProfileList.length)){
      this.handleDesingersScrool()
    }
  }

  handleClose = () =>{
    this.setState({isOpen:false})
  }

  async componentWillUnmount(){
    document.removeEventListener("mousedown",this.handleClickOutside)
  }

  handleDisableResetButton = () => {
    const {
      selectedCreativeFeilds,
      selectedRatings,
      selectedSkills,
      selectedElm,
      isSliderChanged,
      search
    } = this.state;

   const isChanged = [
    search.length,
    selectedCreativeFeilds.length,
    selectedRatings.length,
    selectedSkills.length].some(each => each !== 0)
    
    return !(isChanged || ![configJSON.designerSortData[0],configJSON.clientSortData[0]].includes(selectedElm) || isSliderChanged);

  }

  handleResetClick = () => {
    this.setState({
      selectedCreativeFeilds: [],
      selectedSkills: [],
      selectedRatings: [],
      rateMin: 1000,
      rateMax: 4000,
      search: "",
      isSliderChanged: false,
      currentDesignerPage: 1,
      initialLoading: true,
      desingerProfileList: [],
      selectedElm: this.state.userRole === "designer" ? configJSON.designerSortData[0]: configJSON.clientSortData[0],

    },this.getDesingerSearchResults
    )
  }

  // Customizable Area End
}
